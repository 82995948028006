@import "../../../../../../../../assets/custom/brandColors.css";

.section-item-container {
    background-color: rgba(230, 235, 240, 0.3);
    border-left: 5px solid var(--theme-sapphire);
    border-radius: 4px;
    position: relative;
    padding: 1% 4% 1% 2%;
    font-size: 16px;
    margin-top: 20px;
}
.section-item-container:first-of-type {
    margin-top: 0px;
}

.question-container {
    display: flex;
    justify-content: space-between;
    align-items: end;
    position: relative;
}
.margin-bottom {
    margin-bottom: 2%;
}
.question-title-wrapper {
    display: flex;
    align-items: baseline;
    width: 80%;
}

.question-title-wrapper > span:first-child {
    color: var(--theme-sapphire);
    font-size: 20px;
    font-weight: 600;
    margin-right: 12px;
    position: absolute;
    top: -4px;
    left: -1.5%;
}
.question-title-wrapper > span:last-child {
    color: rgba(204, 74, 67, 1);
}
.question-title-wrapper > p {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;
}
.question-title-wrapper > input {
    padding: 6px 12px;
    font-size: 16px;
    border: 1px solid #898d91;
    border-radius: 4px !important;
    flex-shrink: 10;
    justify-content: space-between;
    align-items: center;
}
.question-title-wrapper svg{
    margin-left: 10px;
    margin-right: -40px;
    cursor: pointer;
}
.answer-wrapper {
    width: 100%;
}

.answer-wrapper .form-check-input {
    width: 26px;
    height: 26px;
    cursor: pointer !important;
}

.answer-wrapper .form-check-input:checked {
    accent-color: #0973ba;
}

.answer-wrapper .form-check-label {
    padding: 0px 8px 0px 6px;
    font-size: 16px;
}
.answer-wrapper .yesno-error-radio input {
    accent-color: var(--error-red) !important;
    visibility: hidden;
    position: relative;
}
.answer-wrapper .yesno-error-radio input::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid var(--error-red);
    visibility: visible;
    position: absolute;
    top: 5px;
}
.answer-wrapper .yesno-error-radio label {
    font-size: 16px;
    color: var(--error-red) !important;
}
.padding-24 {
    padding-left: 24px;
}

.padding-12 {
    padding-left: 12px;
}

.question-type-wrapper {
    margin-top: 2%;
    margin-bottom: 1.85%;
}
.question-type-wrapper textarea {
    padding: 7px 13px;
    font-size: 16px;
    max-height: 10vh;
}
.question-type-dropdown-wrapper {
    display: flex;
    flex-direction: column;
    width: 17%;
}
.question-type-dropdown-wrapper .dropdown-menu {
    width: 100%;
}
.question-type-dropdown-wrapper .dropdown-toggle {
    width: 100%;
    background-color: white !important;
    color: #000 !important;
    font-size: 16px;
    padding: 6px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #898d91;
    border-radius: 4px !important;
}
.question-type-dropdown-wrapper p {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 600;
}

.question-type-dropdown .dropdown .btn {
    width: 100% !important;
}

.section-item-footer {
    display: flex;
    justify-content: end;
    align-items: center;
    border-top: 0.25px solid rgba(0, 0, 0, 0.5);
    padding-top: 19px;
    font-size: 16px;
}
.section-item-footer > button {
    margin-right: 6px;
}
.section-item-footer label {
    font-size: 16px;
    margin-bottom: 0;
}
.section-item-footer .required-wrapper {
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-left: 0.25px solid rgba(0, 0, 0, 0.5);
}
.section-item-footer .required-wrapper .switch {
    margin-bottom: 0;
    margin-left: 16px;
}
.question-container .invalid-input {
    border-color: var(--error-red) !important;
    box-shadow: none;
}
.question-container .error-text {
    color: var(--error-red) !important;
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 0;
    position: absolute;
    bottom: -22px;
}
.option-row .invalid-input {
    border-color: var(--error-red) !important;
    box-shadow: none;
}
.option-row .error-text {
    color: var(--error-red) !important;
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 0;
    position: absolute;
    bottom: -22px;
    left: 10px;
}
.collapsed-section-wrapper > .question-title-wrapper {
    width: unset;
}
.collapsed-section-wrapper > .question-title-wrapper > span {
    position: static;
}

.type-menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.type-menu-item:active,
.type-menu-item:hover {
    background-color: #f0f1f1 !important;
}

.type-menu-item-label {
    font-weight: 400;
    padding-top: 4px;
    padding-left: 10px;
    vertical-align: middle;
    font-size: 16px;
    color: #212529;
    cursor: pointer;
    pointer-events: none;
}

.dropdown-toggle-label {
    font-weight: 400;
    padding-top: 4px;
    padding: 4px 10px 0px 10px;
    vertical-align: middle;
    font-size: 16px;
    color: #212529;
}

.dropdown-toggle-placeholder {
    font-weight: 400;
    padding-top: 4px;
    padding: 4px 10px 0px 0px;
    vertical-align: middle;
    font-size: 16px;
    color: #898d91;
}
@media only screen and (max-width: 1370px) {
    .question-title-wrapper {
        width: 78%;
    }
    .question-type-dropdown-wrapper{
        width: 20%;
    }
}