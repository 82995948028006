.question-type-wrapper {
    margin: 26px 0 22px 12px;
}

.options-wrapper {
    display: flex;
    flex-direction: column;
}

.option-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
    position: relative;
}
.option-row > span {
    cursor: pointer;
}
.option-row .question-title-wrapper .form-check {
    padding-left: 0;
}
.option-row .question-title-wrapper {
    width: 95%;
}
.option-row .question-title-wrapper > input {
    margin-left: 10px;
}
.add-options-button {
    cursor: pointer;
    font-size: 16px;
    color: #0973ba;
    display: flex;
    align-items: center;
    margin-left: -10px;
    width: fit-content;
}
.add-options-button > svg {
    margin-right: 8px;
}
.add-additional-question-button{
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 0.2em;
    font-size: 16px;
    color: #0973ba;
    display: flex;
    align-items: center;
    width: fit-content;
}
.add-additional-question-button > svg {
    margin-right: 8px;
}
.isDisabled {
    cursor: not-allowed !important;
    opacity: 0.65;
}
.follow-up-wrapper > .form-check {
    font-size: 16px;
    display: flex;
}
.follow-up-wrapper .form-check label {
    margin-left: 8px;
    font-size: 16px;
}
.follow-up-wrapper input[type="checkbox"]{
    accent-color: #0973ba;
}
.yes-no-wrapper {
    margin-top: 20px;
    margin-left: 20px;
}
.yes-no-wrapper .child-border {
    position: relative;
}
.yes-no-wrapper .child-border .checkbox-dropdown-wrapper:last-child {
    margin-top: 20px;
}
.yes-no-wrapper .child-border:before {
    content: "";
    width: 1px;
    height: 100%;
    background: #0973ba;
    position: absolute;
    left: -15px;
    top: -20px;
}

.checkbox-dropdown-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    height: 36px;
}
.checkbox-dropdown-wrapper .form-check:before {
    content: "";
    width: 1px;
    height: 10px;
    transform: rotate(90deg);
    background: #0973ba;
    position: absolute;
    left: -10px;
    margin-top: 4px;
}
.checkbox-dropdown-wrapper .question-type-dropdown-wrapper {
    margin-left: 10%;
}
.yes-no-wrapper .question-wrapper {
    margin-top: 0px;
    height: fit-content;
    display: flex;
    flex-direction: column;
}
.yes-no-wrapper .question-wrapper .sub-question-wrapper{
    margin-top: 20px;
}
.yes-no-wrapper .question-wrapper .question-title-wrapper {
    width: 100%;
}
.yes-no-wrapper .question-wrapper .question-type-wrapper {
    margin: 25px 0 0 0;
}
.yes-no-wrapper .follow-up-multi-choice .question-title-wrapper {
    width: 95%;
}
.yes-no-wrapper .question-wrapper .follow-up-multi-choice {
    margin: 25px 0 0 15px;
}
.yes-no-wrapper .question-wrapper .add-additional-question-button {
    margin: 10px 0 0 0px;
    align-self: flex-end;
}
