.custom-toggle-switch-small {
    width: 56px !important;
    height: 18px !important;
}

.custom-toggle-switch-handle-small {
    top: 6px !important;
    left: 8px !important;
    width: 12px !important;
    height: 12px !important;
}

.custom-toggle-switch-input:checked ~ .custom-toggle-switch-handle-small {
    left: 44px !important;
}

.dropdownsize-control {
    height: 20px;
    min-height: 20px;
}

.select-dropdown-small {
    min-width: 86px;
}

.select-dropdown-small.Select > .Select-control {
    max-height: 24px;
}

.select-dropdown-small.Select > .Select-control .Select-placeholder {
    line-height: 24px;
}

.select-dropdown-small.Select > .Select-control .Select-value {
    line-height: 24px !important;
}

.select-dropdown-small.Select > .Select-control .Select-value .Select-value-label {
    line-height: 24px;
}

.select-dropdown-small.Select > .Select-control .Select-input {
    height: 24px;
}
.select-dropdown-small .Select-option {
    padding: 2px 10px !important;
}

.select-dropdown-small.Select .Select-menu-outer {
    font-size: 12px !important;
}

.select-dropdown-small .Select-menu {
    max-height: 88px !important;
}

.send-reminder-popup-tabs.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: #f8f8f8 !important;
}

.send-reminder-popup-modal-body > .tab-content > .active, .nav-tabs > .active {
    background-color: #fff !important;
}
