.locationMismatchModal .modal-dialog {
    display: flex;
    box-shadow: 5px 5px 61px 0px;
    width: 550px;
    height: 266px;
    border-radius: 4.800000190734863px;
    border: 1px;
    height: auto;

    .modal-content {
        border-radius: 4.8px;
        box-sizing: border-box;
        border: none;
        background-color: transparent;

        .modal-header {
            background-color: #fff;
            color: #212529;
            border-bottom: 1px solid #DEE2E6;
            line-height: 24px;
            font-size: 20px;
            padding: 9px 1rem;
            border-top-left-radius: 4.8px;
            border-top-right-radius: 4.8px;

            .closeIcon {
                color: rgba(0, 0, 0, 0.5);
                width: 26px;
                line-height: 24px;
                cursor: pointer;
            }

            .modal-title {
                font-family: Mulish;
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #212529;
            }
        }

        .modal-body {
            height: fit-content;
            background-color: #fff;
            font-size: 14px;
            padding: 1rem 1rem 1rem 0rem;

            .warning-alter {
                line-height: 18px;
                padding: 0.5rem 0;

                p {
                    font-family: Mulish;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    margin: 10px 10px 10px 10px;
                    color: #564016;
                    width: 520px;
                }
            }
        }

        .modal-footer {
            background: #fff;

            button {
                width: 100px;
                box-shadow: none;
            }

            .btn-secondary {
                height: 38px;
                padding: 6px 12px 6px 12px !important;
                border-radius: 2px !important;
                gap: 8px;
                border: 1px solid #898D91 !important;
                background: #F0F1F1;
                color: #212529;
                font-family: Mulish;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                cursor: pointer;
            }

            .btn-primary {
                height: 38px;
                padding: 6px 12px 6px 12px !important;
                border-radius: 2px !important;
                gap: 8px;
                border: 1px solid #0973BA !important;
                background: #0973BA;
                font-family: Mulish;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                cursor: pointer;
            }
        }
    }
}