:root {
    --theme-sapphire: #0973ba;
    --theme-sapphire-700: #054570;
    --theme-navy: #05386b;
    --theme-navy-700: #04274b;
    --gray-500: #6b7075;
    --gray-700: #404346;
    --body-black: #212529;
    --error-red: #cc4a43;
}
