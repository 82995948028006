@import "../../../../../assets/custom/brandColors.css";

.form-builder-container .btn-with-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 20px !important;
    color: #fff;
}

.form-builder-container .btn-with-icon>svg {
    margin-right: 8px;
}

.form-builder-container button {
    font-size: 16px;
    font-weight: 400;
    padding: 6px 12px !important;
}

.form-builder-container .btn-primary {
    background-color: var(--theme-sapphire);
    color: #fff !important;
}

.form-builder-container .preview-button:hover,
.form-builder-container .restart-button:hover,
.form-builder-container .preview-button:active,
.form-builder-container .restart-button:active {
    border-color: var(--theme-sapphire-700) !important;
    font-weight: 400;
    box-shadow: 0px 0px 1px 2px var(--theme-sapphire--700);
    text-decoration: underline;
}
.form-builder-container .add-question-btn:hover svg path {
    fill: white !important;
}

.form-builder-container .btn-outline-secondary {
    background-color: transparent;
    border-color: var(--gray-500);
    color: var(--body-black) !important;
    border: hidden;
    box-shadow: none;
}

.form-builder-container .btn-outline-secondary:hover {
    background-color: unset !important;
    text-decoration: underline;
}

.form-builder-container .btn-outline-secondary:focus {
    box-shadow: none;
}


.form-builder-container .btn-outline-secondary:active {
    background-color: unset !important;
}

.form-builder-container .section-dropdown .btn-primary:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
}

.form-builder-container .section-dropdown .btn-primary:hover svg path {
    fill: #6B7075 !important;
}
.form-builder-container .header-sec-button {
    border: 1px solid var(--gray-500);
    border-radius: 2px;
}
