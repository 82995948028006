@import 'customized.css';

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: #373e4a;
}

body {
    height: 100vh;
}

.hidden {
    display: none !important;
}

.header-section-container {
    float: left;
    width: 100%;
    height: 10%;
}

.detail-section-container {
    height: 100%;
    width: 100%;
}

.text-success {
    color: #28a745 !important;
}

.text-danger {
    color: #dc3545 !important;
}

.text-violet {
    color: #940094 !important;
}

.text-grey {
    color: #666666 !important;
}

.text-warning {
    color: orange !important;
}

.text-blue {
    color: #17a2b8;
}

.text-sheen-green {
    color: #88c656;
}

.navbar.navbar-inverse.sidebar.classic {
    width: 225px !important;
}

.MainContent.classic {
    padding-left: 0 !important;
    padding-top: 0;
}

.MainContent.classic.no-sidemenubar {
    padding-left: 28px !important;
}


.menu-toggle-icon {
    border: 1px solid #ebebec;
    text-align: center;
    width: 24px;
    height: 24px;
    line-height: 24px;
    cursor: pointer;
    color: #51555d;
    font-size: 18px;
    margin: 4px 2px 2px;
    position: relative;
    left: 14.7%;
}

.clearfix {
    clear: both;
}

.main-body-container {
    padding-top: 5px;
}

.profile-icon {
    margin-left: 5px;
}

.detail-section-container {
    padding: 58px 0 0 0;
}

.navbar-fixed-top {
    border-bottom: 1px solid #ebebec;
    background: #fff;
}

.navbar-fixed-top ul.nav>li>a {
    padding: 22px 20px 20px 20px;
    color: #51555d;
    font-size: 12px;
}

.navbar-fixed-top ul.nav>li>a i.right {
    margin-right: 0;
    margin-left: 5px;
}

.navbar-fixed-top ul.nav .dropdown .dropdown-menu {
    width: 140px !important;
    min-width: 140px;
    background-color: #f5f5f6;
    border-radius: 0 0 3px 3px;
    background-clip: padding-box;
    box-shadow: none;
}

.navbar-toggle .icon-bar {
    background: #00008A;
}


.sidemenubar-container.classic,
.main-body-container.classic,
.menu-toggle-icon,
.menu-toggle-icon>.glyphicon,
.sidemenubar-container.classic ul li.panel>a span,
.sidemenubar-container.classic ul#main-menu li.panel>a i.glyphicon-chevron-right,
.sidemenubar-container.classic .sidebar-menu #main-menu li>ul {
    -webkit-transition: all 0.5s ease;
    /* Safari */
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.minimized-menu .main-body-container.classic {
    width: 97%;
}

.minimized-menu .sidemenubar-container.classic {
    width: 0;
    position: absolute;
    z-index: 9;
}

.minimized-menu .menu-toggle-icon {
    left: 0;
}

.minimized-menu .menu-toggle-icon>.glyphicon {
    transform: rotate(180deg);
}

.minimized-menu .sidebar-menu #main-menu li.panel>a {
    height: 40px;
}

.minimized-menu .sidemenubar-container.classic ul li.panel>a span,
.minimized-menu .sidemenubar-container.classic ul#main-menu li.panel>a i.glyphicon-chevron-right,
.minimized-menu .sidemenubar-container.classic .sidebar-menu #main-menu li>ul {
    opacity: 0;
    visibility: hidden;
    height: 0;
    float: left;
}

.btn {
    padding-top: 9px;
    padding-right: 12px;
    padding-left: 34px;
    padding-bottom: 9px;
    position: relative;
    box-shadow: #80808040 0px 0px 3px;
    border: none;
    border-radius: 2px !important;
    font-size: 12px;
}

.padTB15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.toolbar .btn {
    padding: 4px 10px 5px 40px;
    position: relative;
}

.toolbar .btn i {
    position: absolute;
    left: 0;
    padding: 8px;
    top: 0;
    border-radius: 4px 0 0 4px;
    font-size: 10px;
}

.toolbar-left-buttons .btn-default {
    margin-right: 4px;
}

.toolbar-left-buttons .btn-default i {
    background: #e6e6e6;
}

.toolbar-right-buttons .btn-info i {
    background: #31b0d5;
}

.modal.upload-doc-modal .modal-dialog {
    min-width: 100%;
}

.set-access-modal .modal-dialog {
    width: 61%;
}

.set-access-modal .nav-button {
    margin-top: 87px;
    text-align: center;
    font-size: 26px;
}

.set-access-modal .nav-button a {
    color: #373e4a;
}

.set-access-modal .nav-button a:hover,
.set-access-modal .nav-button a:focus {
    color: #818da2;
}

.modal.upload-doc-modal .modal-dialog .divDocumentUpload {
    border: 4px dashed #bababa;
    padding: 8px;
}

.modal.upload-doc-modal .modal-dialog .divDocumentUpload>img {
    max-width: 100%;
}

.modal.upload-doc-modal .modal-dialog table thead th {
    background-color: #f5f5f6;
    color: #a6a7aa;
    font-weight: 400;
}

.modal.upload-doc-modal .modal-dialog table tbody td {
    background-color: #f8f8f8;
}

.modal.upload-doc-modal .modal-dialog .table>thead>tr>th,
.modal.upload-doc-modal .modal-dialog .table .table-bordered>tbody>tr>th,
.modal.upload-doc-modal .modal-dialog .table .table-bordered>tfoot>tr>th,
.modal.upload-doc-modal .modal-dialog .table .table-bordered>thead>tr>td,
.modal.upload-doc-modal .modal-dialog .table .table-bordered>tbody>tr>td,
.modal.upload-doc-modal .modal-dialog .table .table-bordered>tfoot>tr>td {
    border: 1px solid #ebebeb;
}

.modal-body .alert {
    margin: -14px -14px 20px -14px;
    border: none;
    border-radius: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.expanded-table table td.button-cell,
.organizer-delivered-content table td.button-cell,
.user-assignment-content table td.button-cell,
.company-assignment-content table td.button-cell {
    overflow: visible;
}

.expanded-table table td.button-cell .button-group>.btn-toolbar,
.organizer-delivered-content table td.button-cell .button-group>.btn-toolbar,
.user-assignment-content table td.button-cell .button-group>.btn-toolbar,
.company-assignment-content table td.button-cell .button-group>.btn-toolbar {
    float: left;
}

.expanded-table table #organizer-delivered,
.organizer-delivered-content table #organizer-delivered,
.user-assignment-content table #process-return-dropdown,
.company-assignment-content table #process-return-dropdown,
#delivered-return-dropdown {
    padding: 2px 10px;
    padding-top: 0px;
    margin-top: -5px;
    height: 20px;
    border-radius: 0px !important;
    font-size: smaller;
    background: #FFFFFF;
    border: 1px #e0e0e0 solid;
    color: #303641;
}

.expanded-table table td.button-cell .button-group>.btn>.glyphicon,
.organizer-delivered-content table td.button-cell .button-group>.btn>.glyphicon,
.user-assignment-content table td.button-cell .button-group>.btn>.glyphicon,
.company-assignment-content table td.button-cell .button-group>.btn>.glyphicon {
    font-size: 8px;
    padding: 6px 11px;
}

.expanded-table .react-bs-container-body,
.organizer-delivered-content .react-bs-container-body,
.user-assignment-content .react-bs-container-body,
.company-assignment-content .react-bs-container-body {
    overflow: inherit;
}

.batch .react-bs-container-body tr>td {
    overflow: inherit;
}

#pageDropDown {
    display: none;
}

.react-bs-table-tool-bar>.row>div:nth-child(1) {
    float: right;
}

.user-assignment-content .react-bs-table .table-bordered>thead>tr>th,
.company-assignment-content .react-bs-table .table-bordered>thead>tr>th {
    background: #f5f5f6;
}

.react-bs-table .table-bordered>tbody>tr>td {
    padding-top: 5px;
    padding-bottom: 1px;
}

.form-horizontal .text-left {
    text-align: left !important;
    margin-top: 3px;
    font-weight: bold;
}

.modal.clientinfo-edit-modal .modal-dialog {
    width: 36%;
}

h4,
.h4 {
    font-size: 15px;
}

.btn-default {
    color: #303641;
    background-color: #f0f0f1;
    border-color: #f0f0f1;
}

.modal.clientinfo-edit-modal .panel {
    border-radius: 0;
}

.form-control {
    border-radius: 3px;
    font-size: 12px;
    height: 34px;
}

.modal .modal-content {
    border-radius: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
}

.modal-dialog .modal-content {
    border-radius: 1px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    margin: auto;
}

.bootbox .modal-dialog {
    max-width: 600px;
}

.bootbox .modal-dialog .modal-content {
    max-width: 600px;
}

.bootbox .modal-header {
    display: initial;
}

.modal-footer .btn+.btn {
    margin-left: 10px;
}

.main-body-container.classic>div>h3 {
    margin-top: -15px;
}

/*process return tabs*/
#process-return-tabs {
    margin-top: 20px;
}

.process-return-container .parent-tab-head>.nav-tabs {
    margin-bottom: 10px;
}

.process-return-container .parent-tab-head>.nav-tabs>li+li {
    border-left: 1px solid #eee;
}

.process-return-container .parent-tab-head>.nav-tabs>li>a {
    color: #979898;
    font-weight: bold;
    border-radius: 0;
}

.process-return-container .parent-tab-head>.nav-tabs>li.active>a {
    color: #646565;
    border: 1px solid transparent;
    background: #fcfcfc;
}

.process-return-container .parent-tab-head>.nav-tabs>li.active,
li.active~li>a:after {
    background-color: #fcfcfc !important;
}

.process-return-container .parent-tab-head>.nav-tabs>li {
    background: #fcfcfc;
}

.process-return-container .nav-tabs>li a,
.process-return-container .nav-tabs>li a:hover,
.process-return-container .nav-tabs>li a:focus {
    padding: 10px 5px;
    border-bottom: none !important;
    border: none !important;
    background: #fcfcfc !important;
    color: #555;
}

.process-return-container .parent-tab-head>.nav-tabs>li a:after {
    content: '';
    position: absolute;
    display: block;
    height: 10px;
    background-color: #8dc63f;
    top: 100%;
    left: 0;
    right: 0;
}

.process-return-container .parent-tab-head>.nav-tabs>li.active a:after {
    background: rgba(255, 196, 0, 1);
}

.process-return-container .row>.tab-content {
    min-height: 400px;
    background: #f7f7f7;
    padding: 10px 0 40px 0px;
}

.process-return-container .panel-default>.panel-heading {
    background: #fff;
    border: none;
}

.process-return-container .panel-default>.panel-collapse .panel-default>.panel-heading {
    background: #f7f7f7;
}

.process-return-container .panel-default>.panel-collapse .panel-default>.panel-heading .panel-title {
    font-size: 14px;
}

.process-return-container .panel-default>.panel-heading .panel-title>i,
.process-return-container .tab-content .sidebar .panel .panel-title i {
    color: rgba(115, 120, 129, 0.7);
    font-size: 11px;
    font-weight: 100;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.process-return-container .panel-default>.panel-heading .panel-title[aria-expanded='false']>i:before,
.process-return-container .tab-content .sidebar .panel .panel-title[aria-expanded='false'] {
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    content: "+";
}

.panel-title i {
    color: #aaa;
    margin-right: 10px;
    text-shadow: #ffffff -2px 2px 1px;
}

.process-return-container .panel-default {
    border: none;
    margin-bottom: 10px;
}

.process-return-container .return-footer {
    background-color: white;
    padding: 12px 15px 13px;
    margin-bottom: 0px;
    border-top: 1px #ebebec solid;
}

.process-return-container .panel .form-group .control-label,
.process-return-container .client-info-tab .left-sidebar li.list-group-item a.control-label {
    color: #979898;
    font-weight: normal;
    text-align: left;
}

.process-return-container .tab-content .sidebar .list-view .panel .list-group-item a.control-label-bussiness-return {
    color: #ffc400 !important;
}

.process-return-container .client-info-tab .left-sidebar li.list-group-item {
    overflow: hidden;
    border: none;
    padding: 5px 0;
}

#left-group-tab .menu-item ul.nav-pills li>a {
    border-width: 2px 2px 2px 10px;
    padding: 16px 15px;
    margin-right: 0px;
    color: #000;
    background: #fff;
    border-style: dashed dashed dashed solid;
}

#left-group-tab .menu-item ul.nav-pills li.all>a {
    border-color: #E0E0E0;
    border-style: solid;
}

#left-group-tab .menu-item ul.nav-pills li.transmittal>a,
#left-group-tab .menu-item ul.nav-pills>li>a .arrow.yellow-color {
    border-color: #FFC400;
}

#left-group-tab .menu-item ul.nav-pills li.efile>a,
#left-group-tab .menu-item ul.nav-pills>li>a .arrow.orange-color {
    border-color: #F1AF5E;
}

#left-group-tab .menu-item ul.nav-pills li.voucher>a,
#left-group-tab .menu-item ul.nav-pills>li>a .arrow.red-color {
    border-color: #E59FA1;
}

#left-group-tab .menu-item ul.nav-pills li.k1>a,
#left-group-tab .menu-item ul.nav-pills>li>a .arrow.purple-color {
    border-color: #DD88EE;
}

#left-group-tab .menu-item ul.nav-pills li.tax-returns>a,
#left-group-tab .menu-item ul.nav-pills>li>a .arrow.green-color {
    border-color: #8DC63F;
}

#left-group-tab .menu-item ul.nav-pills li.invoice>a,
#left-group-tab .menu-item ul.nav-pills>li>a .arrow.blue-color {
    border-color: #A0BDFA;
}

#left-group-tab .menu-item ul.nav-pills li.deleted>a,
#left-group-tab .menu-item ul.nav-pills>li>a .arrow.black-color {
    border-color: #595959;
}

#left-group-tab .menu-item ul.nav-pills>li>a:hover,
#left-group-tab .menu-item ul.nav-pills>li>a:active,
#left-group-tab .menu-item ul.nav-pills>li>a:focus {
    background: #fff;
    color: #000;
}

#left-group-tab .menu-item ul.nav-pills>li.active>a .arrow {
    border-style: dashed dashed dashed solid;
    border-width: 2px 2px 2px 10px;
    width: 36px;
    height: 36px;
    transform: rotate(45deg);
    position: absolute;
    top: 32px;
    margin-top: -25px;
    right: -19px;
    z-index: 10;
}

#left-group-tab .menu-item ul.nav-pills>li.active>a .arrow.gray-color {
    border-color: #E0E0E0;
    border-style: solid;
}

#left-group-tab .menu-item ul.nav-pills>li.active>a .arrow,
#left-group-tab .menu-item ul.nav-pills li.active>a {
    border-style: solid;
}

#left-group-tab .menu-item .nav-stacked>li+li {
    margin-top: 10px;
}

#left-group-tab .menu-item ul.nav-pills>li.active>a .arrow-cover {
    top: 34px;
    margin-top: -27px;
    right: -16px;
    width: 35px;
    height: 35px;
    border: 2px dashed transparent;
    position: absolute;
    background: #ffffff;
    z-index: 30;
    transform: rotate(45deg);
}

.nopadding-left {
    padding-left: 0 !important;
}

.nopadding-right {
    padding-right: 0 !important;
}

.nopadding {
    padding: 0 !important;
}

#process-return-tabs .pagination>.active>span {
    background-color: #000;
    border-color: #000;
}

#process-return-tabs .pagination>li a {
    color: #000;
    background: #fff;
}

#process-return-tabs .pagination>li a:hover {
    color: #000;
}

#process-return-tabs .pagination>.active>a {
    color: #ffffff;
    background-color: #303641;
    border-color: #303641;
}

#process-return-tabs .pagination>.active>a:hover {
    color: #ffffff;
}

#process-return-tabs .pagination>li.active span:hover {
    background: #000;
    border: 1px solid #000;
}

.process-return-container .return-footer .btn-default {
    margin-right: 2px;
    background: #fff;
    border: 1px #dddddd solid;
}

.process-return-container .return-footer .copyright-text {
    margin-top: 5px;
    color: #949494;
}

.group-tab .groups-title {
    background: #fff;
}

.group-tab .groups-title .move,
.group-tab .groups-title .trash {
    margin-top: 5px;
}

.group-tab .groups-title .move .btn,
.group-tab .groups-title .trash .btn {
    background: #fff;
    border: none;
}

.group-tab .tab-content .panel-default {
    background: none;
}

.group-tab .tab-content {
    overflow-y: scroll;
    height: 85vh;
}

.group-tab .tab-content .group-report-options .checkbox {
    position: absolute;
    left: 140px;
}

.group-tab .tab-content .group-report-options .search {
    position: absolute;
    bottom: 10px;
    left: 115px;
    color: #777272;
}

.group-tab .tab-content .group-report-options .trash {
    position: absolute;
    bottom: 10px;
    left: 135px;
    color: #777272;
}

.group-tab .tab-content canvas {
    float: left;
}

.group-tab .group-report-items {
    width: auto;
}

.group-tab .group-report-items .canvas-outer {
    height: 200px;
    width: 160px;
    position: relative;
    background: #fff;
    margin-right: 20px;
    margin-bottom: 25px;
    margin-top: 5px;
    box-shadow: 0px 0px 10px #FFC400;
}

#left-group-tab-pane-viewAll .panel .panel-heading {
    color: #fff;
}

#left-group-tab-pane-viewAll .trasmittals-panel-all .panel-heading {
    background: #FFC400;
}

#left-group-tab-pane-viewAll .efile-panel-all .panel-heading {
    background: #F1AF5E;
}

#left-group-tab-pane-viewAll .vouchers-panel-all .panel-heading {
    background: #E59FA1;
}

#left-group-tab-pane-viewAll .k1-panel-all .panel-heading {
    background: #DD88EE;
}

#left-group-tab-pane-viewAll .tax-returns-panel-all .panel-heading {
    background: #8DC63F;
}

#left-group-tab-pane-viewAll .invoice-panel-all .panel-heading {
    background: #A0BDFA;
}

#left-group-tab-pane-viewAll .deleted-panel-all .panel-heading {
    background: #595959;
}

.icon-textbox .glyphicon {
    position: absolute;
    background: #eee;
    padding: 10px;
}

.icon-textbox input {
    padding-left: 40px;
}

.btn.icon-button {
    padding: 4px 10px 5px 40px;
    position: relative;
    background: #fff;
}

.btn.icon-button i {
    position: absolute;
    left: 0;
    padding: 8px;
    top: 0;
    border-radius: 4px 0 0 4px;
    font-size: 10px;
    background: #e6e6e6;
}

.attachments-tab .divDocumentUpload {
    border: 4px dashed #bababa;
    padding: 8px;
    background: #fafafa;
    padding-top: 10px;
    margin-bottom: 10px;
}

.attachments-tab .divDocumentUpload img {
    display: block;
    margin: 0 auto;
}

.attachments-tab .title {
    padding: 1px 10px;
    background: #fff;
}

.attachments-tab .panel-default .react-bs-table * {
    border: none;
}

.attachments-tab .panel-default .react-bs-table {
    border: none;
    color: #222;
    font-weight: bold;
}

.attachments-tab .dz-default.dz-message {
    display: none;
}

.attachments-tab .btn-success {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 12px;
    line-height: 1.42857143;
    border-radius: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.attachments-tab .btn-danger {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 12px;
    line-height: 1.42857143;
    border-radius: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.process-return-container .client-info-tab .content,
.process-return-container .transmittals-tab .content,
.process-return-container .tax-returns-tab .content,
.process-return-container .efile-tab .content,
.process-return-container .vouchers-tab .content,
.process-return-container .invoices-tab .content {
    margin-left: 68px;
}

.process-return-container .client-info-tab .right-sidebar,
.process-return-container .transmittals-tab .right-sidebar,
.process-return-container .tax-returns-tab .right-sidebar,
.process-return-container .efile-tab .right-sidebar,
.process-return-container .vouchers-tab .right-sidebar,
.process-return-container .invoices-tab .right-sidebar {
    width: 27%;
}

.tab-content>.active.delivery-options-tab>div {
    background: #fff;
}

.delivery-options-tab .content-box h4 {
    color: #1a8fbf;
    font-size: 16px;
}

.radio-inline input[type="radio"] {
    margin-top: 0;
}

.form-group .glyphicon-question-sign {
    font-size: 14px;
    color: #ffdb6f;
}

.glyphicon-question-sign {
    color: #ffdb6f;
}

.process-return-container .tab-content .sidebar .thumbnail-view .panel canvas {
    margin: 10px auto;
    border: 1px solid #949494;
}

.process-return-container .tab-content .sidebar .panel .panel-title a {
    font-size: 14px;
    padding: 10px 15px;
    color: #373e4a;
}

.process-return-container .tab-content .sidebar .panel {
    background: none;
}

.process-return-container .tab-content .sidebar .panel .panel-body {
    background: #fff;
    max-height: 500px;
    overflow: auto;
}

.process-return-container .tab-content .sidebar .list-view .panel .list-group-item {
    min-height: 20px;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    color: #373e4a;
}

.process-return-container .tab-content .sidebar .list-view .panel .list-group-item a {
    color: #373e4a
}

.process-return-container .tab-content .sidebar .nav-tabs {
    background: #fff;
    border: none;
}

.process-return-container .draggable-outer {
    overflow: hidden;
    max-height: 800px;
}

.process-return-container .report-lense {
    margin-top: 20px;
}

.process-return-container .report-lense ul.list-group {
    margin-bottom: 0
}

.process-return-container .report-lense ul.list-group li {
    padding: 6px 8px;
    text-align: center;
}

.process-return-container .report-lense ul.list-group li a {
    color: #373e4a;
}

.process-return-container .draggable-outer canvas {
    margin: 0 auto;
}

.process-return-container .tab-content .sidebar .list-view .panel div span .glyphicon {
    font-size: 7px;
}

.process-return-container .tab-content .sidebar .list-view .panel div.dot-style span .glyphicon {
    border-radius: 50px;
    top: 0px;
}

.process-return-container .efile-tab .sidebar .list-view .panel div.dot-style span .glyphicon.esignpage {
    background: #008000;
    color: #008000;
}

.process-return-container .efile-tab .sidebar .list-view .panel div.dot-style span .bussiness-return-dot-style {
    background: #ffc400;
    color: #ffc400 !important;
}

.process-return-container .efile-tab .sidebar .list-view .panel div.dot-style span .glyphicon.manualsignpage {
    background: #ffc400;
    color: #ffc400;
}


.process-return-container .vouchers-tab .sidebar .list-view .panel div.dot-style span .glyphicon.payment {
    background: #4DA6DC;
    color: #4DA6DC;
}

.process-return-container .vouchers-tab .sidebar .list-view .panel div.dot-style span .glyphicon.estimated {
    background: #ff7cd3;
    color: #ff7cd3;
}

.group-zoom-modal canvas {
    margin: 0 auto;
}

.draggable-signature {
    border: 1px solid #666;
    position: absolute;
    z-index: 5;
    cursor: move;
    background: #fff;
    margin-top: 35px;
}

.draggable-signature .remove-handle {
    top: -12px;
    background: #000;
    color: #fff;
    cursor: pointer;
    right: -10px;
}

.draggable-signature .edit-handle {
    top: -14px;
    cursor: pointer;
    right: -5px;
}

.draggable-signature .glyphicon-pencil {
    padding: 4px;
    background: #f0f0f1;
}

.draggable-signature .glyphicon-calendar {
    padding: 4px;
    background: #f0f0f1;
}

.draggable-signature .glyphicon-font {
    padding: 4px;
    background: #f0f0f1;
}

.draggable-signature-control {
    border: 1px solid #666;
    position: absolute;
    z-index: 5;
    cursor: move;
    background: #fff;
}

.draggable-signature-control .remove-handle {
    top: -10px;
    background: #000;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    right: -10px;
    position: absolute;
}

.draggable-signature-control .edit-handle {
    top: -11px;
    cursor: pointer;
    right: 10px;
    position: absolute;
}

.draggable-signature-control .glyphicon-pencil {
    padding: 4px;
    background: #f0f0f1;
}

.draggable-signature-control .glyphicon-calendar {
    padding: 4px;
    background: #f0f0f1;
}

.draggable-signature-control .glyphicon-font {
    padding: 4px;
    background: #f0f0f1;
}

.choosable-signature-control {
    position: absolute;
    z-index: 5;
    cursor: auto !important;
    background: transparent;
    background-color: rgb(246 246 251 / 40%);
}

.choosable-signature-control-border {
    border: 1px #666 solid;
}

.choosable-signature-control .edit-handle {
    top: 1px;
    right: 20px;
    font-size: 16px;
    background: #fff;
    cursor: pointer;
    position: absolute;
}

.choosable-signature-control .remove-handle {
    position: absolute;
    right: 3px;
    top: 3px;
    font-size: 14px;
    background: #000;
    color: #fff;
}

.draggable-btn-control,
.choosable-control-item {
    cursor: move !important;
    font-size: 14px;
}

.choosable-control-group {
    position: absolute;
    z-index: 1;
}

.control-remove-handler {
    top: -9px;
    position: absolute;
}

.question-main-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.esignControlWrapper .boxshadow {
    box-shadow: 0px 0px 10px 5px rgba(242, 227, 12, 1);
}

@media only screen and (min-width: 768px) {

    .minimized-menu .sidemenubar-container.classic:hover ul li.panel>a span,
    .minimized-menu .sidemenubar-container.classic:hover ul#main-menu li.panel>a i.glyphicon-chevron-right,
    .minimized-menu .sidemenubar-container.classic:hover .sidebar-menu #main-menu li>ul {
        opacity: 1;
        visibility: visible;
        height: auto;
        float: none;
    }

    .minimized-menu .sidemenubar-container.classic:hover {
        width: 16.66666667%;
    }

    .minimized-menu .main-body-container.classic {
        margin-left: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .detail-section-container .menu-toggle-icon {
        border: none;
        position: initial;
        left: 0;
        width: 0;
    }

    .detail-section-container .navbar-toggle {
        position: absolute;
        top: 0;
        z-index: 9999;
    }

    .detail-section-container .menu-toggle {
        height: 0;
    }

    .sidemenubar-container.classic {
        width: 0;
        padding: 0;
        float: left;
    }

    .sidemenubar-container.classic * {
        padding: 0;
        width: 0;
        overflow: hidden;
    }

    .maximized-menu .sidemenubar-container.classic {
        width: auto;
        padding: 0 15px;
        float: none;
    }

    .maximized-menu .sidemenubar-container.classic * {
        padding: initial;
        width: 100%;
        overflow: visible;
    }

    .maximized-menu .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
        cursor: pointer;
    }

    .modal.clientinfo-edit-modal .modal-dialog {
        width: 90%;
    }

    .modal.source-document-upload-model .modal-dialog {
        min-width: 83%;
    }
}

@media only screen and (max-width: 991px) {
    .menu-toggle-icon {
        left: 30.5%;
    }
}

.compulsory-star {
    color: #c50505;
    position: absolute;
    right: 3px;
    top: 12px;
    font-size: 7px;
}

.txt-ar {
    text-align: right;
}

.txt-al {
    text-align: left;
}

.marL10 {
    margin-left: 10px;
}


.marB10 {
    margin-bottom: 10px;
}

.marB10I {
    margin-bottom: 10px !important;
}

.padT07 {
    padding-top: 7px;
}

.acc-ma-head {
    height: 30px;
    padding-bottom: 10px;
    width: 100%;
    float: left;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #373e4a;
    margin-bottom: 10px;
}

.acc-ma-head-bg {
    background: #fff;
    padding: 0px 2px;
}

.acc-ma-head-txt {
    margin-top: -12px;
    border-bottom: 2px #ebebeb solid;
}

.checkbox-replace,
.radio-replace {
    position: relative;
    padding-left: 0;
}

.btn {
    padding: 9px 12px 9px 34px !important;
    position: relative;
    box-shadow: #80808040 0px 0px 3px;
    border: none;
    border-radius: 2px !important;
}

.btn i {
    float: left;
    right: auto;
    left: 0;
    position: absolute;
    top: 0;
    height: 100%;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    padding: 9px 6px;
    font-size: 12px;
    line-height: 1.42857143;
    text-shadow: 0px 1px 0px #ebebeb;
    border-radius: 2px !important;
}

.btn-info {
    background-color: #21a9e1;
}

.btn-info:hover {
    background-color: #1a8fbf;
}

.btn-info i {
    background-color: #1a8fbf;
}

.btn-white {
    background: #FAFAFA;
    border: 1px #e0e0e0 solid;
    color: #303641;
    margin-right: 2px;
    margin-bottom: 2px;
}

.btn-white:hover {
    background: #ebebeb;
}

.btn-white i {
    text-shadow: 0px 1px 0px #ebebeb;
}

.btn-success i {
    background: #449d44;
}

.btn-danger i {
    background: #c9302c;
}

.btn-default i {
    background: #d4d4d4;
    text-shadow: 0px 1px 0px #ebebeb;
}

.btn-xs [disabled] {
    cursor: not-allowed;
    opacity: 0.65;
}

.btn-ignore-errors {
    padding: 10px !important;
    float: right;
}

.my-account .acc-ma-head {
    height: 30px;
    padding-bottom: 10px;
    width: 100%;
    float: left;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #373e4a;
    margin-bottom: 10px;
}

.my-account .acc-ma-head-bg {
    background: #fff;
    padding: 0px 2px;
}

.my-account .acc-ma-head-txt {
    margin-top: -12px;
    border-bottom: 2px #ebebeb solid;
}

.my-account .marB10 {
    margin-bottom: 10px;
}

.my-account .head_txt_blue {
    color: #21a9e1;
}

.my-account h3 {
    font-size: 21px;
}

.my-account .req_star_new {
    color: #c50505;
    font-size: 7px;
    position: absolute;
    right: -10px;
    top: 12px;
}

.my-account .req_star_txt {
    color: #c50505;
    font-size: 9px;
}

.my-account .btn-info.btn-icon.icon-left {
    padding-right: 12px;
    padding-left: 39px;
}

.my-account .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 12px;
    line-height: 1.42857143;
    border-radius: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.button-hide {
    display: none;
}

.my-account-icon {
    color: grey;
    margin-right: 5px;
    padding-left: 20px;
    padding-right: 10px;
}

/*Company Settings starts*/

.company-settings .new-main-content h3 {
    color: #1a8fbf;
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 16px;
}

.custom-questions-page-title {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.questionHeading {
    color: #212529;
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 20px;
}

.horizontal-divider {
    margin: 4px 0px 12px 0px;
    border-color: rgba(0, 0, 0, 0.2);
}

.new-main-content h4 {
    color: #1a8fbf;
}

.templates-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    row-gap: 20px;
    margin-left: -10px;
}

.template-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 224px;
    margin: 16px;
}

.template {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    width: 100%;
    height: 172px;
    background-color: #E6EBF0 !important;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 4px;
    padding: 0px;
    cursor: pointer;
}

.template:hover {
    background-color: #dfe9f3 !important;
    border: 1px solid rgba(0, 0, 0, 0.275);
}

.scratch {
    background-color: #fff !important;
    cursor: pointer;
}

.create-new-card-container {
    border: 1.5px dashed #05386B !important;
    background-color: white !important;
    border-radius: 4px;
}

.create-new-card-container>span {
    font-size: 16px;
    font-weight: 500;
    color: #212529;
}

.create-new-card-container:hover>span {
    text-decoration: underline;
}

.create-new-card-container>svg {
    margin-bottom: 18px;
}

.template-create-new-button {
    color: #0973BA;
    font-size: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 6px 12px;
    gap: 8px;
    width: 114px;
    height: 38px;
    background-color: #fff;
    border: 1px solid #0973BA;
    border-radius: 2px;
}

.template-create-new-button:hover {
    color: #fff;
    background-color: #0973BA;
}

.template-more-options {
    height: 30px;
    background-color: transparent !important;
    border: none;
    position: absolute !important;
    bottom: 0px;
    right: 0px;
    outline: none;
}

.template-more-options:hover {
    background-color: rgba(0 0, 0, 0.08) !important;
}

.template-more-options .btn-primary.dropdown-toggle {
    border-color: transparent !important;
    outline: none;

}

.template-more-options-menu {
    position: absolute !important;
    top: -10px;
    margin: -8px 0px !important;
    left: -144px !important;
    /*need to update this*/
    width: 160px;
    border: 1px solid #aeaeae;
    background: #fff;
    padding: 0px;
    z-index: 1000;
    border-radius: 2px;
}

.template-menu-item {
    width: 148px;
    height: 32px;
    font-size: 16px;
    cursor: pointer;
}

.template-menu-item:hover {
    width: 100%;
    height: 32px;
    font-size: 16px;
    cursor: pointer;
    background-color: #F0F1F1 !important;
}

.template-menu-item-disabled {
    width: 148px;
    height: 32px;
    font-size: 16px;
    opacity: 0.5;
    cursor: no-drop !important;
}

.template-menu-item-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #212529;
    cursor: pointer;
    pointer-events: none;
}

.delete-confirmation-note {
    font-size: 14px;
    font-weight: bold;
    padding-right: 5px;
}

.red {
    color: #CC4A43;
}


.template .dropdown .btn-primary {
    background-color: transparent;
    padding: 0px 6px 3px 6px !important;
    border: none;
    box-shadow: none !important;
    outline: none;
}

.template .dropdown .btn-primary:focus {
    outline: none;
}

.template-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}

.template-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.template-name {
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.template-description {
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.default-template-button {
    background-color: transparent;
    border: none;
}

.template-modal-container .modal-dialog {
    max-width: 500px !important;
}

.template-modal-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    box-sizing: border-box;
}

.template-modal-row-label {
    font-size: 14px;
}

.template-modal-row-input {
    border: 1px solid #898D91;
    border-radius: 2px;
    padding: 6px 10px;
    font-size: 14px;
}

.create-template-modal-title {
    font-size: 20px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #212529;
}

.template-modal-submit-button {
    margin: 4px !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    width: 88px;
    height: 38px;
    color: #fff;
    background-color: #0973BA;
    border: 1px solid #0973BA;
    border-radius: 2px;
}

.template-modal-cancel-button {
    margin: 4px !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    width: 77px;
    height: 38px;
    color: #202223;
    background-color: #F0F1F1;
    border: 1px solid #898D91;
    border-radius: 2px;
}

.merge-section-modal-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 25px 0px;
}

.merge-section-modal-content>div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.merge-section-modal-content>div>label {
    color: #000;
    font-weight: 500;
}

.merge-section-modal-content>div>div>div {
    border: 1px solid #898D91;
    border-radius: 4px !important;
}

.cq-merge-sections-dropdown {
    margin: 0px 10px;
    width: 75%;
}

.cq-merge-sections-dropdown-control {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.cq-merge-sections-dropdown .Select-input {
    display: none !important;
}

.cq-merge-sections-dropdown .Select-clear-zone {
    display: none;
}

.cq-merge-sections-dropdown .Select-multi-value-wrapper div:not(:first-child) {
    display: none;
}

.cq-merge-sections-dropdown-label {
    width: 100%;
    font-size: 16px !important;
    font-weight: 400 !important;
    padding: 7px 0px 0px 8px;
}

.cq-merge-sections-dropdown-checkbox {
    margin-right: 10px;
}

.company-settings .help-circle {
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border: 1px #ffdb6f solid;
    padding: 2px 0px 1px;
    margin-left: 5px;
    font-size: 12px;
    color: #ffdb6f;
}

.company-settings .marB15 {
    margin-bottom: 15px;
}

.company-settings .btn-new {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 48%, rgba(236, 236, 236, 1) 48%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ececec', GradientType=0);
    border: 1px #4c4c4c solid;
    color: #4c4c4c;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    display: block;
    padding: 7px 0px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 1);
    box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 1);
}

.counter-textbox input {
    width: 50px;
    height: 29px;
    border-width: 1px 0px 1px 0px;
    border-color: #CCC;
    border-style: solid;
    text-align: center;
    vertical-align: bottom;
}

.counter-textbox .btn-left-radius {
    border-radius: 4px 0px 0px 4px;
}

.counter-textbox .btn-right-radius {
    border-radius: 0px 4px 4px 0px;
}

.edit-ins-list {
    height: 100px;
    margin-bottom: 10px;
    border: 1px #ebebeb solid;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    height: 240px;
}

.edit-ins-list ul {
    margin: 0px;
    padding: 0px;
}

.edit-ins-list ul li {
    margin: 0px;
    padding: 5px;
}

.edit-ins-list ul li:hover {
    background: #f0f0f1;
    color: #000000;
    cursor: pointer;
}

.activeManual {
    background: #E9E9EE;
    color: #51555d;
}

/*Company settings ends*/
.add-column-value .help-circle {
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border: 1px #ffdb6f solid;
    padding: 2px 0px 1px;
    margin-left: 5px;
    font-size: 12px;
    color: #ffdb6f;
}

.custom-column .help-circle {
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border: 1px #ffdb6f solid;
    padding: 2px 0px 1px;
    margin-left: 5px;
    font-size: 12px;
    color: #ffdb6f;
}

.custom-column .custom-column-list-group {
    cursor: pointer;
    max-height: 200px;
    height: 200px;
}

.custom-column .list-group {
    padding-left: 0;
    margin-bottom: 20px;
}

.custom-column .col-sm-11 {
    width: 91.66666666666666%;
}

.custom-column .list-group,
custom-column-list-group {
    border: solid;
    border-width: 2px;
    -webkit-border-radius: 5px;
}

.group-management-components .list-border {
    margin: 0px;
    padding: 0px;
    border: 1px solid #ebebeb;
    height: 210px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.group-management-edit-model .list-border {
    margin: 0px;
    padding: 0px;
    border: 1px solid #ebebeb;
    height: 210px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.group-management-edit-model .modal-dialog {
    width: 60% !important;
}

.group-management-edit-model .icon_box {
    background: #dbdbdd;
    border: 1px solid #d0d0d3;
    -webkit-border-radius: 5px;
    width: 40px;
    display: block;
}

.group-management-edit-model .marB10 {
    margin-bottom: 10px;
}

.group-management-edit-model .icon_box_space {
    margin-top: 80px;
}

.group-management-edit-model .hidden {
    display: none;
}

/*Client Instruction start*/


.instruction-set .textStyle {
    color: #ffffff;
    text-align: center;
    font-size: 15px;
    background: #0973ba;
    border: 1px #000000 solid;
    height: 35px;
    line-height: 32px;
    display: block;
    -webkit-border-radius: 5px;
}

.instruction-set .descriptionStyle {
    padding-top: 8px;
}

.content-description .help-circle {
    -webkit-border-radius: 50px;
    border: 1px #ffdb6f solid;
    padding: 2px 0px 1px;
    margin-left: 5px;
    font-size: 12px;
    color: #ffdb6f;
}

.client-instruction .edit-ins-list {
    height: 100px;
    margin-bottom: 10px;
    border: 1px #ebebeb solid;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-border-radius: 5px;
}

.client-instruction .edit-ins-list ul {
    margin: 0px;
    padding: 0px;
}

.client-instruction .edit-ins-list ul ul {
    margin: 0px;
    padding: 5px;
}

.client-instruction .edit-ins-list ul ul:hover {
    background: #f0f0f1;
    color: #000000;
    cursor: pointer;
}

.client-instruction .edit-ins-list .active {
    background: #E9E9EE;
    color: #51555d;
}

.client-instruction .buttonWidth {
    margin-left: 10px;
}

.client-instruction .active {
    background: #E9E9EE;
    color: #51555d;
}

/*Client Instruction end*/
/*Company settings ends*/
.instruction-set .textStyle {
    color: #ffffff;
    text-align: center;
    font-size: 15px;
    background: #0973ba;
    border: 1px #000000 solid;
    height: 35px;
    line-height: 32px;
    display: block;
    -webkit-border-radius: 5px;
}

.instruction-set .descriptionStyle {
    padding-top: 8px;
}

.content-description .help-circle {
    -webkit-border-radius: 50px;
    border: 1px #ffdb6f solid;
    padding: 2px 0px 1px;
    margin-left: 5px;
    font-size: 12px;
    color: #ffdb6f;
}

.message-list .edit-ins-list {
    height: 100px;
    margin-bottom: 10px;
    border: 1px #ebebeb solid;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-border-radius: 5px;
}

.message-list .edit-ins-list ul {
    margin: 0px;
    padding: 0px;
}

.message-list .edit-ins-list ul ul {
    margin: 0px;
    padding: 5px;
}

.message-list .edit-ins-list ul ul:hover {
    background: #f0f0f1;
    color: #000000;
    cursor: pointer;
}

.message-list .edit-ins-list .active {
    background: #E9E9EE;
    color: #51555d;
}

/*Company settings ends*/
/* ===== Begin Checkbox & Radio ===== */
.checkbox-replace,
.radio-replace {
    position: relative;
    padding-left: 0;
    margin: 0;
}

.radio-inline,
.checkbox-inline {
    display: inline-block;
    vertical-align: middle;
    font-weight: normal;
    cursor: pointer;
}

.checkbox-replace input[type="checkbox"],
.radio-replace input[type="checkbox"],
.checkbox-replace input[type="radio"],
.radio-replace input[type="radio"] {
    opacity: 0;
}

.checkbox-replace .cb-wrapper,
.radio-replace .cb-wrapper {
    position: relative;
    display: inline-block;
    border: 1px solid #ebebeb;
    margin-bottom: 0;
    width: 16px;
    height: 15px;
    padding-left: 0px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-replace .cb-wrapper input,
.radio-replace .cb-wrapper input {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
}

.checkbox-replace .cb-wrapper.disabled,
.radio-replace .cb-wrapper.disabled {
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
    opacity: 0.3;
    filter: alpha(opacity=30);
}

.checkbox-replace .cb-wrapper .checked,
.radio-replace .cb-wrapper .checked {
    position: absolute;
    background: #ebebeb;
    display: block;
    left: 2px;
    right: 2px;
    bottom: 2px;
    top: 2px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-transition: 250ms all cubic-bezier(0.455, 0.030, 0.515, 0.955);
    -o-transition: 250ms all cubic-bezier(0.455, 0.030, 0.515, 0.955);
    -webkit-transition: 250ms all cubic-bezier(0.455, 0.030, 0.515, 0.955);
    transition: 250ms all cubic-bezier(0.455, 0.030, 0.515, 0.955);
}

.checkbox-replace .cb-wrapper+label,
.radio-replace .cb-wrapper+label {
    position: relative;
    top: -3px;
    margin-left: 6px;
}

.checkbox-replace:hover .cb-wrapper,
.radio-replace:hover .cb-wrapper {
    -moz-box-shadow: 0 0 0 2px rgba(0, 0, 0, .03);
    -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, .03);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .03);
}

.checkbox-replace.radio-replace .cb-wrapper,
.radio-replace.radio-replace .cb-wrapper {
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
}

.checkbox-replace.radio-replace .cb-wrapper .checked,
.radio-replace.radio-replace .cb-wrapper .checked {
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
}

.checkbox-replace.checked .cb-wrapper .checked,
.radio-replace.checked .cb-wrapper .checked {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    filter: alpha(opacity=100);
}

.checkbox-replace .cb-wrapper,
.radio-replace .cb-wrapper {
    border: 1px solid #d2d2d2;
}

.checkbox-replace .cb-wrapper .checked,
.radio-replace .cb-wrapper .checked {
    background: black;
}

.checkbox-replace:hover .cb-wrapper,
.radio-replace:hover .cb-wrapper {
    -moz-box-shadow: 0 0 0 2px rgba(209.5, 209.5, 209.5, .1);
    -webkit-box-shadow: 0 0 0 2px rgba(209.5, 209.5, 209.5, .1);
    box-shadow: 0 0 0 2px rgba(209.5, 209.5, 209.5, .1);
}

.checkbox-replace.color-primary .cb-wrapper,
.radio-replace.color-primary .cb-wrapper {
    border: 1px solid #303641;
}

.checkbox-replace.color-primary .cb-wrapper .checked,
.radio-replace.color-primary .cb-wrapper .checked {
    background: #303641;
}

.checkbox-replace.color-primary:hover .cb-wrapper,
.radio-replace.color-primary:hover .cb-wrapper {
    -moz-box-shadow: 0 0 0 2px rgba(48, 54, 65, .1);
    -webkit-box-shadow: 0 0 0 2px rgba(48, 54, 65, .1);
    box-shadow: 0 0 0 2px rgba(48, 54, 65, .1);
}

.checkbox-replace.color-red .cb-wrapper,
.radio-replace.color-red .cb-wrapper {
    border: 1px solid #ee4749;
}

.checkbox-replace.color-red .cb-wrapper .checked,
.radio-replace.color-red .cb-wrapper .checked {
    background: #ee4749;
}

.checkbox-replace.color-red:hover .cb-wrapper,
.radio-replace.color-red:hover .cb-wrapper {
    -moz-box-shadow: 0 0 0 2px rgba(238, 71, 73, .1);
    -webkit-box-shadow: 0 0 0 2px rgba(238, 71, 73, .1);
    box-shadow: 0 0 0 2px rgba(238, 71, 73, .1);
}

.checkbox-replace.color-blue .cb-wrapper,
.radio-replace.color-blue .cb-wrapper {
    border: 1px solid #21a9e1;
}

.checkbox-replace.color-blue .cb-wrapper .checked,
.radio-replace.color-blue .cb-wrapper .checked {
    background: #21a9e1;
}

.checkbox-replace.color-blue:hover .cb-wrapper,
.radio-replace.color-blue:hover .cb-wrapper {
    -moz-box-shadow: 0 0 0 2px rgba(33, 169, 225, .1);
    -webkit-box-shadow: 0 0 0 2px rgba(33, 169, 225, .1);
    box-shadow: 0 0 0 2px rgba(33, 169, 225, .1);
}

.checkbox-replace.color-green .cb-wrapper,
.radio-replace.color-green .cb-wrapper {
    border: 1px solid #00a651;
}

.checkbox-replace.color-green .cb-wrapper .checked,
.radio-replace.color-green .cb-wrapper .checked {
    background: #00a651;
}

.checkbox-replace.color-green:hover .cb-wrapper,
.radio-replace.color-green:hover .cb-wrapper {
    -moz-box-shadow: 0 0 0 2px rgba(0, 166, 81, .1);
    -webkit-box-shadow: 0 0 0 2px rgba(0, 166, 81, .1);
    box-shadow: 0 0 0 2px rgba(0, 166, 81, .1);
}

.checkbox-replace.color-gold .cb-wrapper,
.radio-replace.color-gold .cb-wrapper {
    border: 1px solid #fad839;
}

.checkbox-replace.color-gold .cb-wrapper .checked,
.radio-replace.color-gold .cb-wrapper .checked {
    background: #fad839;
}

.checkbox-replace.color-gold:hover .cb-wrapper,
.radio-replace.color-gold:hover .cb-wrapper {
    -moz-box-shadow: 0 0 0 2px rgba(250, 216, 57, .1);
    -webkit-box-shadow: 0 0 0 2px rgba(250, 216, 57, .1);
    box-shadow: 0 0 0 2px rgba(250, 216, 57, .1);
}

.checkbox-replace.color-white .cb-wrapper,
.radio-replace.color-white .cb-wrapper {
    border: 1px solid #ffffff;
}

.checkbox-replace.color-white .cb-wrapper .checked,
.radio-replace.color-white .cb-wrapper .checked {
    background: #ffffff;
}

.checkbox-replace.color-white:hover .cb-wrapper,
.radio-replace.color-white:hover .cb-wrapper {
    -moz-box-shadow: 0 0 0 2px rgba(255, 255, 255, .1);
    -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, .1);
    box-shadow: 0 0 0 2px rgba(255, 255, 255, .1);
}

.radio label,
.radio-inline label,
.checkbox label {
    min-height: 16px;
    padding-left: 0px;
    font-weight: normal;
}

.radio-inline .cb-wrapper {
    min-height: 16px;
}

/* ===== End Checkbox & Radio ===== */

/* ===== Setting Popup ===== */
.help-icon {
    font-size: 18px;
    color: #ffdb6f;
}

.help-icon:hover,
.help-icon:focus {
    color: #ffaa00;
}

.my-def-help {
    position: absolute;
    right: 15px;
    top: 6px;
    height: 20px;
    width: 20px;
    text-align: center;
}

.upload-help {
    vertical-align: sub;
    padding-left: 5px;
}

/* ===== Begin Common Style ===== */
*:focus {
    outline: none !important
}

h3 {
    font-size: 21px;
}

.marL03 {
    margin-left: 3px;
}

.marL05 {
    margin-left: 5px;
}

.marL10 {
    margin-left: 10px;
}

.marL20 {
    margin-left: 20px;
}

.marL24 {
    margin-left: 24px;
}

.marL30 {
    margin-left: 30px;
}

.marT0 {
    margin-top: 0px;
}

.marT5 {
    margin-top: 5px;
}

.marT10 {
    margin-top: 10px;
}

.marT20 {
    margin-top: 20px;
}

.marR05 {
    margin-right: 5px;
}

.marB05 {
    margin-bottom: 5px;
}

.marB15 {
    margin-bottom: 15px;
}

.marB25 {
    margin-bottom: 25px;
}

.padL00 {
    padding-left: 0px;
}



.padR30 {
    padding-right: 30px;
}

.padL25 {
    padding-left: 25px;
}

.padT05 {
    padding-top: 5px;
}

.padT06 {
    padding-top: 6px;
}

.padT25 {
    padding-top: 25px;
}

.padL05 {
    padding-left: 5px;
}

.padL15 {
    padding-left: 15px;
}

.padL10 {
    padding-left: 10px;
}

.padR00 {
    padding-right: 0px;
}

.padR05 {
    padding-right: 5px;
}

.padR15 {
    padding-right: 15px;
}

.padR20 {
    padding-right: 20px !important;
}

.padT10 {
    padding-top: 10px;
}

.padB10 {
    padding-bottom: 10px;
}

.txt-ar {
    text-align: right;
}

.txt-ac {
    text-align: center;
}

.dis-inline {
    display: inline-block !important;
}

.btn-only {
    padding: 6px 12px !important;
}

input:focus {
    outline: none;
}

.float-none {
    float: none;
}

.btnuploadscancopy {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    background-color: transparent;
    color: transparent;
    width: 40%;
    height: 100%;
}

.btnuploadscancopyw100 {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    background-color: transparent;
    color: transparent;
    width: 100%;
    height: 100%;
}

table th {
    border-bottom-width: 0px !important;
}

table td {
    vertical-align: middle !important;
}

table .tbl-btn-danger {
    font-size: 8px;
    border: none;
    padding: 7px 9px 5px;
    margin-left: 3px;
}

.menu-toggle-icon {
    font-size: 11px;
    width: 25px;
}

.width100 {
    width: 100%;
}

.min-height200 {
    min-height: 200px;
}

.va-supper {
    vertical-align: super;
}

.va-sub {
    vertical-align: sub;
}

.modal-header .modal-title {
    color: #1a8fbf;
    font-size: 16px;
    font-weight: bold;
}

.modal-header .close {
    font-size: 21px;
    padding-top: 3px;
    font-family: Tahoma;
}

.pos-relative {
    position: relative;
}

.compulsory-star1 {
    color: #c50505;
    font-size: 7px;
    position: absolute;
    right: -10px;
    top: 12px;
}

.compulsory-star-profile {
    color: #c50505;
    font-size: 7px;
    position: absolute !important;
    right: -10px;
    top: 12px !important;
}

.compulsory-star-only {
    color: #c50505;
    font-size: 7px;
}

.btn-file {
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    padding: 6px 12px !important;
    height: 34px;
    line-height: 24px;
}

.btn-file>input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    transform: translate(-300px, 0) scale(4);
    font-size: 23px;
    direction: ltr;
    cursor: pointer;
}

.fileinput {
    margin-bottom: 9px;
    display: inline-block;
}

.form-control[readOnly] {
    cursor: not-allowed;
    background-color: #fefeff;
    box-shadow: none;
}

.readonly-background-color {
    background-color: #eeeeee !important;
}

/* ===== End Common Style ===== */
.settings-checkbox .checkbox-replace {
    display: inline-block;
}

/* ===== Begin Header, Leftside, Content Style ===== */
.wrapper {
    width: 100%;
    display: table;
    height: 100%;
    table-layout: fixed;
}

.navbar-container {
    display: table-row;
    border-bottom: 1px solid #ebebec;
    background: #fff;
    min-height: 57px;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    z-index: 150;
}

.navbar-container .navbar-brand-logo {
    display: block;
    padding: 6px 10px;
}

.navbar-container .navbar-brand-logo img {
    max-width: 145px;
}

.navbar-container .right-menu {
    text-align: right;
    padding: 0px;
}

.navbar-container .right-menu .nav li {
    display: inline-block;
}

.navbar-container .right-menu .nav li a {
    color: #51555d;
    padding: 20px 15px;
    display: inline-block;
    text-decoration: none;
}

.navbar-container .right-menu .nav li a:hover {
    color: #337ab7;
}

.navbar-container .right-menu .nav li span .dropdown-menu {
    background: #f5f5f6;
    border: none;
    border-radius: 0 0 3px 3px;
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.navbar-container .right-menu .nav li span .dropdown-menu li {
    display: block;
}

.navbar-container .right-menu .nav li span .dropdown-menu li:nth-child(2) {
    border-bottom: 1px #e5e5e5 solid;
}

.navbar-container .right-menu .nav li span .dropdown-menu li a {
    padding: 10px 10px;
    font-size: 12px;
}

.navbar-container .right-menu .nav li span .dropdown-menu li a i {
    padding-right: 10px;
    font-size: 14px;
    color: #17a2b8;
    text-shadow: white 1px 0px 1px;
}

.navbar-container .right-menu .nav li span .dropdown-menu li a i:hover {
    color: #bee5eb;
    text-shadow: grey 0px 1px 1px;
}

.navbar-container .right-menu .nav li span .dropdown-menu li a i {
    padding-right: 10px;
}

.navbar-container .right-menu .nav li .navbar-toggle {
    float: none;
    margin-right: 5px;
}

.navbar-container .right-menu .nav li .navbar-toggle {
    float: none;
    margin-right: 5px;
}

.page-container {
    width: 100%;
    display: table;
    height: 100%;
    table-layout: fixed;
}

.main-content {
    width: 100%;
    display: table-cell;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 57px 15px;
}

.footer {
    border-top: 1px solid #ebebec;
    background: #FFFFFF;
    position: fixed;
    bottom: 0px;
    left: 225px;
    right: 0px;
    z-index: 15;
}

.footer .copyright {
    padding: 10px 15px;
}

.finish-processing-modal .modal-dialog {
    width: 40%;
}

.finish-processing-modal .sidebar-menu-inner #finishprocessing-main-menu {
    list-style: none;
    border: 1px solid #ebebec;
}

.finish-processing-modal .sidebar-menu-inner #finishprocessing-main-menu li {
    border-color: #ebebec;
    position: relative;
    margin: 0;
    font-size: 12px;
    border-bottom: none;
    color: #51555d;
    background-image: none;
    border-radius: 0;
}

.finish-processing-modal .sidebar-menu-inner #finishprocessing-main-menu li a {
    position: relative;
    display: block;
    padding: 10px 20px;
    color: #51555d;
    z-index: 2;
    -moz-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
    -o-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
    -webkit-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
    transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
    padding: 10px 7px !important;
    border-bottom: none;
    font-size: 14px;
    text-decoration: none;
}


.finish-processing-modal .sidebar-menu-inner #finishprocessing-main-menu li>a {
    border-bottom: 1px solid #ebebec;
}

.finish-processing-modal .sidebar-menu-inner #finishprocessing-main-menu li>a:hover {
    background-color: #f7f7f7;
    color: #51555d;
}


div.icon-div {
    padding: 4px 10px 5px 40px;
    position: relative;
    background: #fff;
    color: #333;
    cursor: pointer;
    border: 1px solid #d2d2d2;
    border-radius: 4px 0 0 4px;
}

div.icon-div i {
    position: absolute;
    left: 0;
    padding: 8px;
    top: 0;
    font-size: 10px;
    background: #e6e6e6;
}

.elementDragging {
    z-index: 1000;
    width: 42%;
    border: 0px;
    transform: scale(0.85);
    transition: all 100ms;
}

.esignatureControlbackground {
    position: absolute !important;
    width: 90%;
}

.groupPanel {
    max-height: inherit !important;
}

.groupCanvas {
    display: block;
    height: 160px;
    width: 160px;
}

.esignControlWrapper {
    position: relative;
    display: inline-flex;
}

.esignControlPageWrapper {
    text-align: center;
}

.esignEROStamp img {
    height: 100%;
    width: 100%;
    border: 1px #666 solid;
}

.esignEROStamp .remove-handle-ero {
    top: -45px;
    background: #000;
    color: #fff;
    cursor: pointer;
    left: 10px;
    font-size: 16px;
    line-height: 1 !important;
    margin: 0px 0px 0px 0px !important;
}

.esignControlDropFocus {
    cursor: crosshair;
}

.groupMenuItemFocus {
    -webkit-box-shadow: 0px 0px 56px 5px rgba(0, 0, 0, 0.39);
    -moz-box-shadow: 0px 0px 56px 5px rgba(0, 0, 0, 0.39);
    box-shadow: 0px 0px 56px 5px rgba(0, 0, 0, 0.39);
}

.groupItemDragging {
    cursor: move;
    z-index: 99 !important;
}

.groupItemDragging.dragging {
    margin-left: 65px;
    margin-top: -95px;
    width: 75px !important;
}

.groupItemDragging .dragging {
    height: auto !important;
    width: auto !important;
}

.groupItemDragging .dragging .groupCanvas {
    height: 91px !important;
    width: 70px !important;
    box-shadow: 0px 0px 10px #FFC400;
}

.groupItemDragging .dragging a {
    display: none !important;
}

.groupItemDragging .dragging input {
    display: none !important;
}

.groupItemDragging .dragging label {
    display: none !important;
}

.groupTabContentDragging {
    overflow-y: initial !important;
}

.groupPanelOuterDragging {
    overflow: initial !important;
}

#trasmittals-panel-all .canvas-outer,
#transmittals-panel .canvas-outer {
    box-shadow: 0px 0px 10px #FFC400;
}

#efile-panel-all .canvas-outer,
#efile-panel .canvas-outer {
    box-shadow: 0px 0px 10px #F1AF5E;
}

#vouchers-panel-all .canvas-outer,
#vouchers-panel .canvas-outer {
    box-shadow: 0px 0px 10px #E59FA1;
}

#k1-panel-all .canvas-outer,
#k1-panel .canvas-oute {
    box-shadow: 0px 0px 10px #DD88EE;
}

#tax-returns-panel-all .canvas-outer,
#tax-returns-panel .canvas-outer {
    box-shadow: 0px 0px 10px #8DC63F;
}

#invoice-panel-all .canvas-outer,
#invoice-panel .canvas-outer {
    box-shadow: 0px 0px 10px #A0BDFA;
}

#deleted-panel-all .canvas-outer,
#deleted-panel .canvas-outer {
    box-shadow: 0px 0px 10px #000;
}

.thumbnailFormName {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.thumbnail-view-canvas {
    display: block;
    height: 80px;
    width: 100px;
}


/* ===== Begin Responsive ===== */
/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
    .main-content {
        display: block;
        padding: 60px 15px 0px 15px;
    }

    .footer {
        position: relative;
        left: 0px;
    }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {

    .main-content {
        display: block;
        padding: 57px 15px 0px;
    }

    .footer {
        position: relative;
        left: 0px;
    }

    .navbar-container .nav li a {
        padding: 20px 15px;
    }

    .navbar-container .navbar-brand-logo {
        padding: 10px 0px;
    }

    .navbar-container .navbar-brand-logo img {
        max-width: 125px;
    }

    .mobile-hidden {
        display: none;
    }

    .visible {
        display: block !important;
    }
}

/* Landscape phones and smaller */
@media (max-width: 480px) {
    .main-content {
        display: block;
        padding: 57px 15px 0px;
    }

    .footer {
        position: relative;
        left: 0px;
    }

    .mob-hide-txt {
        display: none;
    }

    .navbar-container .nav li a {
        padding: 20px 15px;
    }

    .navbar-container .navbar-brand-logo {
        padding: 10px 0px;
    }

    .navbar-container .navbar-brand-logo img {
        max-width: 125px;
    }

    h3 {
        font-size: 18px;
    }

    .mobile-hidden {
        display: none;
    }

    .visible {
        display: block !important;
    }
}

.filters {
    display: inline-block;
    position: absolute;
    right: 0px;
    z-index: 10;
    font-size: 14px;
    margin-right: 10px;
}

.filter-icon {
    padding: 6px 6px 6px 28px !important;
}

.btn-filter {
    padding: 6px 8px !important;
}

.btn-border {
    border: 1px #dddddd solid;
    background: #FFFFFF;
}

.btn-border:hover {
    background: #f5f5f6 !important;
}

.doc-pagination .pagination {
    margin: 0px 0px 5px 0px !important;
}

.hypControl {
    padding-left: 32px;
    padding-top: 5px;
}

.overflowText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.btn-border {
    border: 1px #dddddd solid;
    background: #FFFFFF;
}

.btn-border:hover {
    background: #f5f5f6 !important;
}

.doc-pagination .pagination {
    margin: 0px 0px 5px 0px !important;
}

.send_voc_reminders {
    position: relative;
    padding-left: 35px;
}

.send_voc_reminders .svr_checkbox {
    position: absolute;
    top: 10px;
    left: 0px;
    height: 20px;
    width: 35px;
}

/*Invoice tab CSS start*/
.invoices-tab .form-horizontal .display-invoice-button {
    display: none
}

/*Invoice tab CSS end*/

/*Process return tab CSS start*/
.process-return-container .clearfix .parent-tab-head .dispaly-invoice-tab {
    display: none
}

.process-return-container .clearfix .parent-tab-head .dispaly-k1-tab {
    display: none
}

/*Process return tab CSS end*/

/* UserManagement */
.member-of .group_members ul {
    margin: 0px;
    padding: 0px;
    border: 1px solid #ebebeb;
    height: 210px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}

.member-of .group_members ul li {
    margin: 0px;
    padding: 5px 7px;
    list-style-type: none;
    margin-bottom: 1px;
}

.member-of .group_members ul li:hover {
    background: #f7f7f7;
    cursor: pointer;
}

.member-of .icon_box_space {
    margin-top: 80px;
    margin-left: 50px;
}

.member-of .icon_box {
    background: #dbdbdd;
    border: 1px solid #d0d0d3;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    width: 40px;
    display: block;
}

.member-of .icon_box i {
    font-size: 26px;
}

.member-of .icon_box:hover {
    background: #d0d0d3;
    color: #333;
    cursor: pointer;
}

.member-of .activeUser {
    background: #E9E9EE !important;
    color: #51555d !important;
}

.addUserModal .modal-header {
    border-bottom: none
}

/*.editUserModal .modal-header {
    border-bottom: none
}*/

.memberOfModal .modal-header {
    border-bottom: none
}

/* Large desktops and laptops */
@media (min-width: 1200px) {
    .addUserModal .modal-dialog {
        width: 70% !important;
    }

    .memberOfModal .modal-dialog {
        width: 60% !important;
    }

    .editUserModal .modal-dialog {
        width: 60% !important;
    }
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    .addUserModal .modal-dialog {
        width: 70% !important;
    }

    .memberOfModal .modal-dialog {
        width: 60% !important;
    }

    .editUserModal .modal-dialog {
        width: 75% !important;
    }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
    .addUserModal .modal-dialog {
        width: 90% !important;
    }

    .memberOfModal .modal-dialog {
        width: 85% !important;
    }

    .editUserModal .modal-dialog {
        width: 100% !important;
    }
}

/* UserManagement end*/

/* MySettings */
.my-settings .userscanneruploadsign {
    padding: 2%;
    border: 1px solid;
    border-radius: 9px;
    min-height: 100px;
    min-width: 300px;
    max-width: 300px;
}

.signature-delegation .group_members ul {
    margin: 0px;
    padding: 0px;
    border: 1px solid #ebebeb;
    height: 210px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}

.signature-delegation .group_members ul li {
    margin: 0px;
    padding: 5px 7px;
    list-style-type: none;
    margin-bottom: 1px;
}

.signature-delegation .group_members ul li:hover {
    background: #f7f7f7;
    cursor: pointer;
}

.signature-delegation .icon_box_space {
    margin-top: 80px;
}

.signature-delegation .icon_box {
    background: #dbdbdd;
    border: 1px solid #d0d0d3;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    width: 40px;
    display: block;
}

.signature-delegation .icon_box i {
    font-size: 26px;
}

.signature-delegation .icon_box:hover {
    background: #d0d0d3;
    color: #333;
    cursor: pointer;
}

.signature-delegation .activeUser {
    background: #E9E9EE !important;
    color: #51555d !important;
}

/* MySettings end*/
.client-instruction-dropdown-width {
    width: 100%;
}

.btnuploadsettings {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    background-color: transparent;
    color: transparent;
    width: 100%;
    height: 100%;
}

/* MySettings end*/

/* Delivered Returns */
.delivered-return-download-link {
    font-size: 14px;
    font-weight: bold;
    color: #88c656;
    cursor: pointer;
    list-style-type: none;
}

.preparer-message .display-button {
    display: none;
}

/* filter start*/
.filter-dropdown {
    width: 360px;
}

.filter-name {
    width: 100%;
    padding: 3px;
}

/*Filter ends */
/*client Tracking start*/
.client-tracking-modal .modal-dialog {
    width: 900px;
    margin: 30px auto;
}

/*client Tracking end*/

/*reminder update start*/
.reminder-update-modal .modal-dialog {
    width: 830px;
}

/*reminder update end*/

/*upload form start*/
.upload-forms-modal .modal-content {
    width: 1135px;
}

.upload-forms-modal .modal-dialog {
    width: 1142px;
}

/*upload form end*/

/* Client info tab overpayment applied start */
.overpayment-applied-hide {
    display: none
}

/* Client info tab overpayment applied end */
/*upload form end*/

/*resend acces link start*/
.button-group .list-disabled {
    pointer-events: none;
    opacity: 0.6;
}

.list-disabled {
    pointer-events: none;
    opacity: 0.6;
}

/*resend acces link end*/
.k1-tab .btn-new {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 48%, rgba(236, 236, 236, 1) 48%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ececec', GradientType=0);
    border: 1px #4c4c4c solid;
    color: #4c4c4c;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    display: block;
    padding: 7px 0px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 1);
    box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 1);
}

/* Finish processing start*/
.finish-processing-menu-name {
    padding-left: 10px;
}

/* Finish processing end*/
#tax-year-dropdown+ul.dropdown-menu {
    min-width: 10px;
}

.company-logo {
    max-width: 145px !important;
    height: 45px;
}

.company-logo-header {
    width: 215px !important;
    float: left;
}

/*Invoice tab*/
.align-button {
    text-align: center;
}

/*Invoice tab*/

/*ClientInfo*/
.element-padding {
    padding: 1px
}

/*ClientInfo*/

/*EFile tab*/
.legend {
    background-color: #ebebeb;
    padding: 15px;
    margin-bottom: 20px;
    color: #000;
    font-size: inherit;
}

/*EFile tab*/

div.filepicker {
    background-image: url("../../images/droparea.PNG");
    /* image not coming if giving image path directly*/
    height: 180px;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
}

div.filepicker1 {
    background-image: url("../../images/droparea.PNG");
    /* image not coming if giving image path directly*/
    height: 180px;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
}

div.dz-message {
    display: none
}

.content-Loader::after {
    content: '';
    display: block;
    position: absolute;
    left: 48%;
    top: 40%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: black;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
    z-index: 100;
}

.content-loader-text {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.0);
    font-weight: bold;
    font-size: 100%;
    margin-top: 25%;
}

.overlay-loader {
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.overlay-loader::after {
    content: '';
    display: block;
    left: 48%;
    top: 40%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: black;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
    position: absolute;
}

.overlay-loader-text {
    text-align: center;
    /*background-color: rgba(255,255,255,0.0);*/
    font-weight: bold;
    font-size: 110%;
    color: #363535;
    position: relative;
    top: 50%;
    left: 0;
}

#process-return-dialog .modal-dialog {
    width: 100%;
    margin: auto auto;
    padding: 10px;
}


.panel-heading-icon {
    color: #ccc;
    margin-right: 10px;
    text-shadow: #fcfcfc 0px 2px 1px;
}

.modal-heading-icon {
    color: #555;
    margin-right: 10px;
    font-size: 16px;
    vertical-align: text-top;
}

.form-group-tile {
    background: white;
    border-style: solid solid solid solid;
    padding: 16px 15px;
    margin-top: 10px;
    border-width: 2px 2px 2px 32px;
    border-radius: 5px;
}

.form-group-tile i {
    margin-right: 15px;
    margin-left: -39px;
    font-size: 14px;
    color: #333;
    text-shadow: #f7f7f7 0px 1px 2px;
    /*vertical-align: text-top;*/
}

.form-group-header i {
    margin-right: 15px;
    vertical-align: text-top;
    color: #333;
    text-shadow: white 0px 1px 1px;
}

.page-thumbnail {
    margin-bottom: 10px;
    box-shadow: #808080 4px 4px 6px;
}

.page-thumbnail .panel-heading {
    padding: 5px 10px;
}

.right-pointer {
    border: solid;
    border-width: 2px 2px 2px 32px;
    position: relative;
    font-size: 13px;
    background: white;
    height: 50px;
    line-height: 40px;
    margin-bottom: 20px;
    vertical-align: middle;
    color: white;
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px 5px 5px 5px;
    width: 91% !important;
}

.right-pointer span {
    color: #888;
    margin-left: 10px;
}

.right-pointer.None {
    border-color: grey;
}

.right-pointer.Vouchers {
    border-color: #E59FA1;
}

.right-pointer.EFile {
    border-color: #F1AF5E;
}

.right-pointer.Deleted {
    border-color: #595959;
}

.right-pointer.Transmittals {
    border-color: #FFC400;
}

.right-pointer.TaxReturns {
    border-color: #8DC63F;
}

.right-pointer.Invoice {
    border-color: #A0BDFA;
}

.right-pointer.K1 {
    border-color: #dd88ee;
}

.arrow-icon {
    font-size: 16px;
    color: #333;
    text-shadow: #f7f7f7 0px 1px 2px;
    margin-top: 14px;
}

.arrow-right:before {
    content: "";
    position: absolute;
    right: -30px;
    top: -2px;
    border-top: 26px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 25px solid transparent;
    width: 31px;
}

.arrow-right:after {
    content: "";
    position: absolute;
    right: -31px;
    top: 0px;
    border-top: 24px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 23px solid white;
    width: 32px;
}

.arrow-right.None:before {
    border-left-color: grey;
}

.arrow-right.Transmittals:before {
    border-left-color: #FFC400;
}

.arrow-right.Vouchers:before {
    border-left-color: #E59FA1;
}

.arrow-right.EFile:before {
    border-left-color: #F1AF5E;
}

.arrow-right.Deleted:before {
    border-left-color: #595959;
}

.arrow-right.TaxReturns:before {
    border-left-color: #8DC63F;
}

.arrow-right.Invoice:before {
    border-left-color: #A0BDFA;
}

.arrow-right.K1:before {
    border-left-color: #dd88ee;
}


/* thumbnail page */


.item.list-group-item {
    float: none;
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px;
}

.item.list-group-item:nth-of-type(odd):hover,
.item.list-group-item:hover {
    background: #428bca;
}

.item.list-group-item .list-group-image {
    margin-right: 10px;
}

.item.list-group-item .thumbnail {
    margin-bottom: 0px;
}

.item.list-group-item .caption {
    padding: 9px 9px 0px 9px;
}

.item.list-group-item:nth-of-type(odd) {
    background: #eeeeee;
}

.item.list-group-item:before,
.item.list-group-item:after {
    display: table;
    content: " ";
}

.item.list-group-item img {
    float: left;
}

.item.list-group-item:after {
    clear: both;
}

.list-group-item-text {
    margin: 0 0 11px;
}

.thumbnail {
    margin-bottom: 20px;
    padding: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    box-shadow: grey 1px 1px 5px;
}

.thumbnail .caption {
    padding: 9px;
    color: #333;
    background-color: gainsboro;
}

.thumbnail .caption h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    text-shadow: white 0px 1px 0px;
}

.thumbnail .caption p {
    font-weight: 900;
    margin-top: 6px;
    color: #888181;
    text-shadow: white 0px 1px 0px;
}

/* thumbnail page */
.fillWidth {
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
}

.button-dropdown-width {
    max-width: 175px;
    min-width: 172px;
}

.disable-tinymce-div {
    pointer-events: none;
}

dl {
    margin-top: 15px;
    font: inherit;
}

dl dt {
    float: left;
    width: 100px;
    text-align: left;
    margin-left: 15px;
}

dt dd {
    margin-left: 115px;
}

pre {
    display: block;
    /*font-family: monospace;*/
    white-space: pre;
    margin: 1em 0;
}

/* Report Filter Changes Starts here*/
ul.filter-item {
    margin: 0px 11px 0px 11px;
}

ul.filter-item i {
    margin: 3px;
    cursor: pointer;
}

ul.filter-item table:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

ul.filter-item table.active {
    background-color: #dfdfea;
}

/* Report Filter Changes Ends here*/


.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: -95%;
    max-width: 180px;
    margin-top: -6px;
    margin-right: -1px;
    -webkit-border-radius: 6px 6px 6px 6px;
    -moz-border-radius: 6px 6px 6px 6px;
    border-radius: 6px 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: left;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-right-color: #999;
    margin-top: 5px;
    margin-right: 10px;
    margin-left: 15px;
}

.dropdown-submenu:hover>a:after {
    border-left-color: #ffffff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 6px 6px 6px;
    -moz-border-radius: 6px 6px 6px 6px;
    border-radius: 6px 6px 6px 6px;
}

.dropdown-menu-right {
    margin-left: 0;
}

.dropdown-menu-ul {
    right: 100% !important;
    max-width: none !important;
    left: unset !important;
}

.dropdown-submenu-right>a:after {
    float: right !important;
}

.defaul-filter-lable {
    padding: 5px 10px;
    margin: 5px 0;
}

.applied-filter {
    margin-right: 15px;
    font-style: italic;
}

.ddl-icon {
    margin-right: 10px;
    margin-left: 10px;
    color: #17a2b8;
    width: 15.75px;
}

.ddl-icon:hover {
    color: #bee5eb;
    text-shadow: grey 0px 1px 1px;
}


.padB7 {
    padding-bottom: 7px !important;
}

.label-blue {
    background-color: #00b0f0 !important;
    color: #fff;
}

.custom-toggle-switch {
    position: relative;
    display: block;
    vertical-align: top;
    width: 75px;
    height: 25px;
    padding: 3px;
    margin: 0;
    background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px);
    background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px);
    border-radius: 18px;
    box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    box-sizing: content-box;
}

.custom-toggle-switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    box-sizing: content-box;
}

.custom-toggle-switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 10px;
    text-transform: uppercase;
    background: #eceeef;
    border-radius: inherit;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
    box-sizing: content-box;
}

.custom-toggle-switch-label:before,
.custom-toggle-switch-label:after {
    position: absolute;
    top: 50%;
    margin-top: -.5em;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
    box-sizing: content-box;
}

.custom-toggle-switch-label:before {
    content: attr(data-off);
    right: 11px;
    color: #aaaaaa;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.custom-toggle-switch-label:after {
    content: attr(data-on);
    left: 11px;
    color: #FFFFFF;
    text-shadow: 0 1px rgba(0, 0, 0, 0.2);
    opacity: 0;
}

.custom-toggle-switch-input:checked~.custom-toggle-switch-label {
    background: #21a9e1;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.custom-toggle-switch-input:checked~.custom-toggle-switch-label:before {
    opacity: 0;
}

.custom-toggle-switch-input:checked~.custom-toggle-switch-label:after {
    opacity: 1;
}

.custom-toggle-switch-input:checked[disabled]~.custom-toggle-switch-label {
    background: #c9e2f9;
}

.custom-toggle-switch-handle {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 21px;
    height: 22px;
    background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
    background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
    border-radius: 100%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.custom-toggle-switch-handle:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -6px 0 0 -6px;
    width: 12px;
    height: 12px;
    background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
    background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
    border-radius: 6px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}

.custom-toggle-switch-input:checked~.custom-toggle-switch-handle {
    left: 56px;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

/* Transition
========================== */
.custom-toggle-switch-label,
.custom-toggle-switch-handle {
    transition: All 0.3s ease;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}

/*  
========================== */
.react-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
}

.react-tagsinput--focused {
    border-color: #a5d24a;
}

.react-tagsinput-tag {
    background-color: #fff2ca;
    border-radius: 2px;
    border: 1px solid #FFC107;
    color: black;
    display: inline-block;
    font-family: sans-serif;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2px;
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: " ×";
}

.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 100%;
}


/* BUG 23653, Fix for: Some part of filter and export to excel button container 
	is overlapped by the react-bs-table-tool-bar div*/
/*.react-bs-table-tool-bar > div.row > div:first-child {
    display: none;
}*/

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: .75rem;
}

.h5,
h5 {
    font-size: 1.25rem;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: Mulish, "Segoe UI VSS (Regular)", "Segoe UI", "-apple-system", BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    /*font-size: 12px;*/
}

.bg {
    background: linear-gradient(to top right, #060628, #1f245a, #682359);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.window {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 700px;
    height: 400px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 4px;
    z-index: 5;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    transition: top 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1), width 0.5s 0.5s cubic-bezier(0.1, 1.2, 0.3, 1), opacity 0.3s, -webkit-transform 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1);
    transition: top 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1), transform 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1), width 0.5s 0.5s cubic-bezier(0.1, 1.2, 0.3, 1), opacity 0.3s;
    transition: top 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1), transform 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1), width 0.5s 0.5s cubic-bezier(0.1, 1.2, 0.3, 1), opacity 0.3s, -webkit-transform 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1);
}

.window .window-controls {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    height: 30px;
    width: 60px;
}

.window .window-controls .maximize {
    background-color: #e8eaec;
    position: absolute;
    top: -30px;
    opacity: 0;
    left: 0;
    z-index: 9;
    width: 200px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #b4b9be;
    cursor: pointer;
    transition: opacity 0.3s 0.5s, top 0s 0.8s;
}

.window .window-controls .close,
.window .window-controls .minimize {
    opacity: 0.5;
    cursor: pointer;
}

.window .window-controls .close:hover,
.window .window-controls .minimize:hover {
    opacity: 0.7;
}

.window .window-controls .close:active,
.window .window-controls .minimize:active {
    opacity: 1;
}

.window .window-controls .close {
    width: 30px;
    height: 30px;
    float: left;
}

.window .window-controls .close:before,
.window .window-controls .close:after {
    position: absolute;
    content: "";
    width: 12px;
    height: 2px;
    background-color: black;
    position: absolute;
    border-radius: 2px;
    top: 14px;
    left: 9px;
}

.window .window-controls .close:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.window .window-controls .close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.window .window-controls .minimize {
    width: 30px;
    height: 30px;
    float: left;
    position: relative;
}

.window .window-controls .minimize:before,
.window .window-controls .minimize:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 2px;
    background-color: black;
    position: absolute;
    border-radius: 2px;
    top: 14px;
    left: 10px;
}

.window .window-controls .minimize:before {
    -webkit-transform: translateX(-3px) rotate(45deg);
    transform: translateX(-3px) rotate(45deg);
}

.window .window-controls .minimize:after {
    -webkit-transform: translateX(3px) rotate(-45deg);
    transform: translateX(3px) rotate(-45deg);
}

.window .window-sidebar {
    width: 200px;
    height: 400px;
    background-color: #e8eaec;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 30px;
    z-index: 4;
}

.window .window-sidebar .content-block {
    height: 60px;
    width: 100%;
    position: relative;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.window .window-sidebar .content-block:before {
    position: absolute;
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: black;
    top: 10px;
    left: 10px;
    opacity: 0.05;
}

.window .window-sidebar .content-block:after {
    position: absolute;
    content: "";
    width: 120px;
    height: 10px;
    background-color: black;
    top: 25px;
    left: 60px;
    opacity: 0.05;
    border-radius: 5px;
}

.window .window-content {
    position: absolute;
    top: 0;
    right: 0;
    width: 500px;
    height: 400px;
}

.window .window-content:before {
    position: absolute;
    content: "";
    width: 460px;
    height: 200px;
    background-color: black;
    top: 20px;
    left: 20px;
    opacity: 0.05;
    border-radius: 5px;
}

.window .window-content:after {
    position: absolute;
    content: "";
    width: 380px;
    height: 10px;
    background-color: black;
    top: 240px;
    left: 60px;
    opacity: 0.05;
    border-radius: 5px;
}

.minimized .window {
    top: 100%;
    -webkit-transform: translate(-50%, -30px);
    transform: translate(-50%, -30px);
    width: 200px;
    opacity: 0.5;
}

.minimized .window:hover {
    opacity: 0.75;
}

.minimized .window .maximize {
    top: 0;
    opacity: 1;
    transition: opacity 0.3s 0.5s, top 0s 0.5s;
}

.text {
    position: fixed;
    top: 50%;
    left: 50%;
    color: white;
    -webkit-transform: translate(350px, -200px);
    transform: translate(350px, -200px);
    z-index: 10;
    padding-left: 20px;
}

.text .title {
    font-size: 20px;
    line-height: 20px;
    display: block;
    margin-bottom: 5px;
}

.text .name {
    display: block;
    opacity: 0.5;
}



/*   Process Return Modal  */

.modal-header .modal-header-button {
    padding: 3px 5px;
    float: right;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    opacity: .4;
    margin-right: 2px;
    border: 1px solid transparent;
    background-color: transparent;
}

.modal-header .modal-header-button:hover:not([disabled]) {
    background-color: #efeaea;
    text-shadow: 0 1px 0 #fff;
    border: 1px solid #c1c1c1;
}

.custom-modal .custom-modal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    cursor: pointer;
}

.custom-modal .modal-title:hover {
    cursor: move;
}

.custom-modal .bottom-bar {
    bottom: 0px;
    left: 0px;
    position: fixed;
    right: 0px;
    z-index: 2000;
    width: 10%;
    position: absolute;
    cursor: pointer;
}

.custom-modal .bottom-bar-item {
    background-color: #4CAF50;
    color: white;
    height: 33px;
    border-radius: 3px 3px 0px 0px;
    align-items: stretch;
    margin-right: 10px;
    padding: 9px;
    display: flex;
}

.custom-modal .bottom-bar-item-icon {
    float: right;
    margin-left: 15px;
}

.custom-modal .bottom-bar-item-title {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
}

.custom-modal .bottom-bar-item:hover {
    background-color: #76bd79;
}

.custom-modal .modal-footer {
    min-height: 60px;
    bottom: 0px;
    position: absolute;
    width: inherit;
}

.custom-modal .modal-body {
    height: 88%;
    overflow: auto;
    padding: 10px !important;
}

.custom-modal .modal-header {
    border-bottom: 1px solid #e5e5e5;
}

.custom-modal .bottom-bar-item-title-icon {
    color: #ffeb3b;
    line-height: initial;
}

.custom-list-group-item-singleline {
    position: relative;
    display: block;
    padding: 6px 8px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px dotted #ddd;
    height: 37px;
    white-space: nowrap;
}

.custom-list-group-item-singleline.custom-active,
.custom-list-group-item-singleline.custom-active:hover,
.custom-list-group-item-singleline.custom-active:focus {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #b8daff;
}

.modalIcon {
    color: grey;
    margin-right: 10px;
    vertical-align: text-bottom;
    font-size: 20px;
}


.custom-list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px dotted #ddd;
}



/* custom toggle button css*/
.switch {
    position: relative;
    /* Fixed Calendar issue*/
    /*display: inline-block;*/
    width: 44px;
    height: 18px;
    margin: 0 0 10px 0;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #0973BA;
}

input:focus+.slider {
    box-shadow: 0 0 1px #0973BA;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/**/

/*Hiding tiny-mce status bar*/
.mce-statusbar .mce-container-body {
    display: none !important;
}

/*End Hiding tiny-mce status bar*/

/*Start custom-check-box*/
.custom-checkbox {
    padding-left: 3px;
}

.custom-checkbox label {
    display: inline-block;
    position: relative;
    padding-left: 5px;
}

.custom-checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.custom-checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #555555;
}

.custom-checkbox input[type="checkbox"] {
    opacity: 0;
}

.custom-checkbox input[type="checkbox"]:focus+label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.custom-checkbox input[type="checkbox"]:checked+label::after {
    font-family: 'Font Awesome 5 Free';
    content: "\f00c";
    font-weight: 900;
}

.custom-checkbox input[type="checkbox"]:disabled+label {
    opacity: 0.65;
}

.custom-checkbox input[type="checkbox"]:disabled+label::before {
    background-color: #eeeeee;
    cursor: not-allowed;
}

.custom-checkbox.checkbox-circle label::before {
    border-radius: 50%;
}

.custom-checkbox.checkbox-inline {
    margin-top: 0;
}


.custom-checkbox-primary input[type="checkbox"]:checked+label::before {
    background-color: #428bca;
    border-color: #428bca;
}

.custom-checkbox-primary input[type="checkbox"]:checked+label::after {
    color: #fff;
}

.highlighted .right-pointer {
    border-color: red;
    border-style: dashed;
}

.draggable-ghost-list-item-icon {
    float: left;
    margin-right: 10px;
    margin-top: 3px;
}


.draggable-ghost-list-center {
    padding-top: 50%;
}



/*End custom-check-box*/


.appTitle {
    display: inline;
    vertical-align: middle;
    margin-left: 15px;
    font-size: 26px;
    /*text-shadow: #585858 -1px -1px 1px;
    color: #dedede;*/
}

.wordbreak {
    word-break: break-word;
}

/*Right Panel style starts here*/
.left-panel,
.right-panel {
    margin-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.bookmarksPane {
    overflow-y: auto;
    overflow-x: hidden;
}

.bookmarksPane .panel,
.right-panel .panel {
    border-radius: 0px !important;
    margin-bottom: 5px !important;
}

.right-panel .panel-title {
    cursor: pointer;
}

.bookmarksPane .panel-default .panel-heading,
.right-panel .panel-default .panel-heading {
    color: #fff;
    background-color: #c0bfcf;
    border-color: #ddd;
    border-radius: 0px !important;
    background-color: #EEEEEE;
    color: #696969;
}

.right-panel .panel-title i {
    color: #808080;
    margin-right: 10px;
    text-shadow: 1px 1px 1px white;
}

/*Right Panel style ends here*/

ul[aria-labelledby='move-to-dropdown'] {
    z-index: 100000;
}

.user-management-content table td.button-cell .button-group>.btn-toolbar {
    float: left;
}

.user-management-content table td.button-cell {
    overflow: visible;
}

.user-management-content .react-bs-container-body {
    overflow: inherit;
}

.pageTitle:hover {
    text-decoration-color: #337ab7 !important;
    text-decoration: underline;
}

.btn-plus {
    padding: 4px 4px;
    font-size: 12px;
    text-shadow: 0px 1px 0px #ebebeb;
}

.padLR-0 {
    padding-left: 0px;
    padding-right: 0px;
}

.padLR-2 {
    padding-left: 2px;
    padding-right: 2px;
}

.marB0 {
    margin-bottom: 0px;
}

.padL8 {
    padding-left: 8px;
}

.trash-icon {
    color: #1a8fbf;
}

.cursor-pointer {
    cursor: pointer;
}

.textalign-center {
    text-align: center;
}

.textalign-right {
    text-align: right;
}

.marL0 {
    margin-left: 0px;
}

.padTB7 {
    padding-top: 7px;
    padding-bottom: 7px;
}

.marL8 {
    margin-left: 8px;
}


/*uploaded files table cell styles start*/
.uploaded-file-remove-btn {
    padding-top: 14px;
    padding-bottom: 14px;
}

.uploaded-file-remove-btn i {
    padding: 6px 6px;
}

.uploaded-file-progressbar {
    height: 28px;
    margin-bottom: 5px;
    margin-top: 5px;
}

/*uploaded files table cell styles End*/

/*Start Price Tag*/

.tags {
    float: left;
    position: relative;
    width: 70px;
    height: 26px;
    margin-left: 20px;
    padding: 0 12px;
    line-height: 27px;
    background: rgb(20, 112, 137);
    color: white;
    font-size: 15px;
    text-decoration: none;
    text-align: center;
}

.tags:before {
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
}

.tags:after {
    content: "";
    position: absolute;
    top: 11px;
    width: 4px;
    height: 4px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: #fff;
    -moz-box-shadow: -1px -1px 2px #004977;
    -webkit-box-shadow: -1px -1px 2px #004977;
    box-shadow: -1px -1px 2px #004977;
}

.left-tag {
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
}

.left-tag:before {
    left: -15px;
    border-color: transparent rgb(20, 112, 137) transparent transparent;
    border-width: 15px 15px 15px 0;
}

.left-tag:after {
    left: -2px;
}

.right-tag {
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
}

.right-tag:before {
    right: -13px;
    border-color: transparent transparent transparent rgb(20, 112, 137);
    border-width: 13px 0 13px 13px;
}

.right-tag:after {
    right: -2px;
}

.tags[class*="#147089"] {
    background: rgb(20, 112, 137);
}

.left-tag[class*="#147089"]:before {
    border-color: transparent rgb(20, 112, 137) transparent transparent;
}

.right-tag[class*="#147089"]:before {
    border-color: transparent transparent transparent rgb(20, 112, 137);
}

.tags[class*="#E86650"] {
    background: rgb(232, 102, 80);
}

.left-tag[class*="#E86650"]:before {
    border-color: transparent rgb(232, 102, 80) transparent transparent;
}

.right-tag[class*="#E86650"]:before {
    border-color: transparent transparent transparent rgb(232, 102, 80);
}

/*End Price Tag*/

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.border-radius-0 {
    border-radius: 0px !important;
}

.imageBorder {
    border: 1px solid grey;
    border-radius: 16px;
    padding: 12px;
}

.image-upload>input {
    display: none;
}

.imageupload {
    width: 40px;
    cursor: pointer;
    background: #d3d3d3;
    float: left;
    cursor: pointer;
}

.span-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
}

/* Added refund start*/
.addedRefund,
.addedVoucher {
    padding-top: 8px;
    padding-bottom: 5px;
    padding-left: 8px;
    padding-right: 8px;
}

#popover-added-refund {
    text-align: center;
}

#popover-added-refund .icon-textbox {
    text-align: initial;
}

/* Added refund end*/
/*height File uploaded Success list */
.Modal-UploadUl {
    margin-left: 8px;
    max-height: 150px;
    overflow-y: auto;
}

.passwordHeader {
    width: 100%;
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    color: #1a8fbf;
}

.borderBottom01 {
    border-bottom: 1px solid #d3d3d3;
}

.marL15 {
    margin-left: 15px;
}

.marR15 {
    margin-right: 15px;
}

.marR20 {
    margin-right: 20px;
}

.marL60 {
    margin-left: 60px;
}

.marL33 {
    margin-left: 33px;
}

.member-of .nav-button {
    margin-top: 87px;
    text-align: center;
    font-size: 26px;
}

.member-of .nav-button a {
    color: #373e4a;
}

.member-of .nav-button a:hover,
.member-of .nav-button a:focus {
    color: #818da2;
}


/*Custom radio button start*/
.magic-checkbox+label,
.magic-radio+label {
    font-weight: normal !important;
}

@keyframes hover-color {
    from {
        border-color: #c0c0c0;
    }

    to {
        border-color: #3e97eb;
    }
}

.magic-radio,
.magic-radio-sm,
.magic-checkbox,
.magic-checkbox-sm {
    position: absolute;
    display: none;
}

.magic-radio[disabled],
.magic-checkbox[disabled],
.magic-radio-sm[disabled],
.magic-checkbox-sm[disabled] {
    cursor: not-allowed;
}

.magic-radio+label,
.magic-checkbox+label,
.magic-radio-sm+label,
.magic-checkbox-sm+label {
    position: relative;
    display: block;
    padding-left: 30px;
    cursor: pointer;
    vertical-align: middle;
}

.magic-radio-sm+label,
.magic-checkbox-sm+label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
}

.magic-radio+label:hover:before,
.magic-checkbox+label:hover:before,
.magic-radio-sm+label:hover:before,
.magic-checkbox-sm+label:hover:before {
    animation-duration: 0.4s;
    animation-fill-mode: both;
    animation-name: hover-color;
}

.magic-radio+label:before,
.magic-checkbox+label:before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: '';
    border: 1px solid #9d9d9d;
}

.magic-radio-sm+label:before,
.magic-checkbox-sm+label:before {
    position: absolute;
    top: -11px;
    left: 0;
    display: inline-block;
    width: 15px;
    height: 15px;
    content: '';
    border: 1px solid #9d9d9d;
}

.magic-radio+label:after,
.magic-checkbox+label:after,
.magic-radio-sm+label:after,
.magic-checkbox-sm+label:after {
    position: absolute;
    display: none;
    content: '';
}

.magic-radio[disabled]+label,
.magic-checkbox[disabled]+label,
.magic-radio-sm[disabled]+label,
.magic-checkbox-sm[disabled]+label {
    cursor: not-allowed;
    color: #e4e4e4;
}

.magic-radio[disabled]+label:hover,
.magic-radio[disabled]+label:before,
.magic-radio[disabled]+label:after,
.magic-checkbox[disabled]+label:hover,
.magic-checkbox[disabled]+label:before,
.magic-checkbox[disabled]+label:after,
.magic-radio-sm[disabled]+label:hover,
.magic-radio-sm[disabled]+label:before,
.magic-radio-sm[disabled]+label:after,
.magic-checkbox-sm[disabled]+label:hover,
.magic-checkbox-sm[disabled]+label:before,
.magic-checkbox-sm[disabled]+label:after {
    cursor: not-allowed;
}

.magic-radio[disabled]+label:hover:before,
.magic-checkbox[disabled]+label:hover:before,
.magic-radio-sm[disabled]+label:hover:before,
.magic-checkbox-sm[disabled]+label:hover:before {
    border: 1px solid #e4e4e4;
    animation-name: none;
}

.magic-radio[disabled]+label:before,
.magic-checkbox[disabled]+label:before,
.magic-radio-sm[disabled]+label:before,
.magic-checkbox-sm[disabled]+label:before {
    border-color: #e4e4e4;
}

.magic-radio:checked+label:before,
.magic-checkbox:checked+label:before,
.magic-radio-sm:checked+label:before,
.magic-checkbox-sm:checked+label:before {
    animation-name: none;
}

.magic-radio:checked+label:after,
.magic-checkbox:checked+label:after,
.magic-radio-sm:checked+label:after,
.magic-checkbox-sm:checked+label:after {
    display: block;
}

.magic-radio+label:before,
.magic-radio-sm+label:before {
    border-radius: 50%;
}

.magic-radio+label:after,
.magic-radio-sm+label:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #3e97eb;
}

.magic-radio:checked+label:before,
.magic-radio-sm:checked+label:before {
    border: 1px solid #3e97eb;
}

.magic-radio:checked[disabled]+label:before,
.magic-radio-sm:checked[disabled]+label:before {
    border: 1px solid #c9e2f9;
}

.magic-radio:checked[disabled]+label:after,
.magic-radio-sm:checked[disabled]+label:after {
    background: #c9e2f9;
}

.magic-checkbox+label:before,
.magic-checkbox-sm+label:before {
    border-radius: 1px;
}

.magic-checkbox+label:after {
    top: 2px;
    left: 7px;
    box-sizing: border-box;
    width: 6px;
    height: 12px;
    transform: rotate(45deg);
    border-width: 2px;
    border-style: solid;
    border-color: #fff;
    border-top: 0;
    border-left: 0;
}

.magic-checkbox-indeterminate+label:after {
    top: 4px !important;
    left: 9px !important;
    width: 0px !important;
    height: 12px !important;
    transform: rotate(90deg) !important;
}

.magic-checkbox-sm+label:after {
    top: -10px;
    left: 5px;
    box-sizing: border-box;
    width: 6px;
    height: 11px;
    transform: rotate(45deg);
    border-width: 2px;
    border-style: solid;
    border-color: #fff;
    border-top: 0;
    border-left: 0;
}

.magic-checkbox-sm-indeterminate+label:after {
    top: -8px !important;
    left: 7px !important;
    width: 0px !important;
    height: 10px !important;
    transform: rotate(90deg) !important;
}

.magic-checkbox:checked+label:before,
.magic-checkbox-sm:checked+label:before {
    border: #3e97eb;
    background: #3e97eb;
}

.magic-checkbox:checked[disabled]+label:before,
.magic-checkbox-sm:checked[disabled]+label:before {
    border: #c9e2f9;
    background: #c9e2f9;
}


/*Custom radio button end*/
.pos-absolute {
    position: absolute;
}

.marL-20 {
    margin-left: -20px;
}

/*finish popover start*/

.finishPopover {
    max-width: none;
    /* Max Width of the popover (depending on the container!) */
    font-size: 12px;
}

.finishPopover .arrow {
    top: 100%;
}

.finishPopover .custom-list-group-item {
    padding: 6px 15px;
}

.finishPopover hr {
    margin-top: 10px;
    margin-bottom: 10px;
}

.finishPopover .warning {
    color: #8a6d3b;
}

.finishPopover .info {
    color: #31708f;
}

.finishPopover .rdo-btn-group {
    display: inline-flex;
    margin-bottom: 10px;
    padding-left: 5px
}

.finishPopover .popover-content {
    height: 95%
}

.finishPopover .rdo-btn-group div+div {
    margin-left: 20px;
}

/*finish popover end*/

.tabs-left,
.tabs-right {
    border-bottom: none;
    padding-top: 2px;
}

.tabs-left {
    border-right: 1px solid #ddd;
}

.tabs-left>li,
.tabs-right>li {
    float: none;
    margin-bottom: 2px;
}

.tabs-left>li {
    margin-right: -1px;
}

.tabs-left>li.active>a,
.tabs-left>li.active>a:hover,
.tabs-left>li.active>a:focus {
    border-bottom-color: #ddd;
    border-right-color: transparent;
}

.tabs-left>li>a {
    border-radius: 4px 0 0 4px;
    margin-right: 0;
    display: block;
}

.selected-message-view {
    background-color: #efefef;
    min-height: 100px;
    color: black;
    margin-bottom: 0;
    overflow: scroll;
    height: 120px;
}


/* PDFJS Viewer with text layer*/
.pdfTextLayer {
    position: absolute;
    left: 0;
    top: 0;
    right: 15px;
    bottom: 15px;
    overflow: hidden;
    opacity: 0.2;
    line-height: 1.0;
    user-select: text;
}

.annotationLayer {
    position: absolute;
    left: 0;
    top: 0;
    right: 15px;
    bottom: 15px;
    overflow: hidden;
    opacity: 1;
}

.pdfTextLayer>span {
    color: transparent;
    position: absolute;
    white-space: pre;
    cursor: text;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
}

.pdfTextLayer .highlight {
    margin: -1px;
    padding: 1px;
    background-color: rgb(180, 0, 170);
    border-radius: 4px;
}

.pdfTextLayer .highlight.begin {
    border-radius: 4px 0px 0px 4px;
}

.pdfTextLayer .highlight.end {
    border-radius: 0px 4px 4px 0px;
}

.pdfTextLayer .highlight.middle {
    border-radius: 0px;
}

.pdfTextLayer .highlight.selected {
    background-color: rgb(0, 100, 0);
}

.pdfTextLayer ::-moz-selection {
    background: rgb(0, 0, 255);
}

.pdfTextLayer ::selection {
    background: rgb(0, 0, 255);
}

.pdfTextLayer .endOfContent {
    display: block;
    position: absolute;
    left: 0px;
    top: 100%;
    right: 0px;
    bottom: 0px;
    z-index: -1;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.pdfTextLayer .endOfContent.active {
    top: 0px;
}

.btn-default-active {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
}

.pdfTextLayer .endOfContent.active {
    top: 0px;
}

.group-view-content {
    height: 70vh;
    overflow-y: auto;
}

.group-view-hedear {
    background-color: #f9f7f7;
    padding: 5px;
    margin-bottom: 5px;
    border: 1px solid #eae7e7;
    border-radius: 3px;
    display: block;
}

.background-light-yellow {
    background-color: #f9f8ed;
}

.color-change-animation {
    animation: colorchange 2s;
    -webkit-animation: colorchange 2s;
}

.group-toolbar .pages-selected {
    padding-left: 4px;
    border-left: 1px solid #b1a2c1;
    margin-left: 5px;
    color: #3F51B5;
}


.page-thumbnail-checkbox {
    margin: 5px;
    top: 0px;
    right: 5px;
    position: absolute;
    z-index: 1;
}

@keyframes colorchange {
    0% {
        background: #f9f7f7;
    }

    25% {
        background: #eff992;
    }

    100% {
        background: #f9f7f7;
    }
}

.popover-footer {
    padding: 10px;
    text-align: right;
}

.popover-footer>.btn {
    margin-left: 5%;
}

.voucher.payment-voucher {
    color: blue;
}

.voucher.estimated-voucher {
    color: pink;
}

.zIndex10 {
    z-index: 10;
}

.header-delivery-options {
    color: #1a8fbf;
}


.divUploadZone {
    border: 4px dashed #bababa;
    padding: 8px;
}

.fit-to-screen {
    height: 100% !important;
    width: 100% !important;
    left: 0px !important;
    top: 0px !important;
}

.pg-viewer-wrapper {
    height: 700px !important;
    overflow-y: auto !important;
}

/* Custom Pagination*/

.custom-pagination {
    border-radius: 4px !important;
    margin-bottom: 0px !important;
}


.custom-pagination>li {
    display: inline;
}

.custom-pagination>li>a,
.custom-pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    background: #FFFFFF;
    border: 1px #c9edfe solid;
    color: black;
}

.custom-pagination>li:first-child>a,
.custom-pagination>li:first-child>span {
    margin-left: 0;
}

.custom-pagination>li>a:hover,
.custom-pagination>li>span:hover,
.custom-pagination>li>a:focus,
.custom-pagination>li>span:focus {
    z-index: 2;
    color: #23527c;
    background-color: #eee;
    border-color: #ddd;
}

.custom-pagination>.active>a,
.custom-pagination>.active>span,
.custom-pagination>.active>a:hover,
.custom-pagination>.active>span:hover,
.custom-pagination>.active>a:focus,
.custom-pagination>.active>span:focus {
    z-index: 3;
    cursor: default;
    color: white;
    background-color: #1d9bff;
}

.custom-pagination>.disabled>span,
.custom-pagination>.disabled>span:hover,
.custom-pagination>.disabled>span:focus,
.custom-pagination>.disabled>a,
.custom-pagination>.disabled>a:hover,
.custom-pagination>.disabled>a:focus {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
}

.custom-pagination-lg>li>a,
.custom-pagination-lg>li>span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    overflow-y: auto;
}


.custom-pagination-lg>li:last-child>a,
.custom-pagination-lg>li:last-child>span {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.custom-pagination-sm>li>a,
.custom-pagination-sm>li>span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.custom-pagination>.active {
    z-index: 0 !important;
    border-color: #47a7f3 !important;
}

.custom-pagination-container {
    display: inline-flex;
}

.pagination-label-container {
    margin: auto;
    margin-left: 10px !important;
}

.custom-pagination-container .search-field {
    width: 30px;
    height: 25px;
    margin: auto;
    text-align: center;
}

.group-panel-body {
    margin-left: -30px;
    background-color: #FEFEFE;
    padding-top: 0px !important;
}

/* End */
.datePicker {
    padding-right: 70px;
}

.filePreviewModalContent {
    max-height: 800px;
    max-width: 800px;
}

#process-return-groups ul li a {
    z-index: 10 !important;
    background-color: #fff;
}

#process-return-groups ul li a.page-link {
    background-color: #337ab7 !important;
}

#process-return-groups ul.custom-pagination li.active a {
    background-color: grey !important;
}

.taxpayer-sign-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(242, 227, 12, 1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(242, 227, 12, 1);
    box-shadow: 0px 0px 10px 5px rgba(242, 227, 12, 1)
}

.spouse-sign-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(247, 241, 126, 1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(247, 241, 126, 1);
    box-shadow: 0px 0px 10px 5px rgba(247, 241, 126, 1);
}

.partnership-sign-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(245, 237, 86, 1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(245, 237, 86, 1);
    box-shadow: 0px 0px 10px 5px rgba(245, 237, 86, 1);
}

.ero-sign-highlight {
    -webkit-box-shadow: 0px 0px 10px 5px rgba(209, 196, 8, 1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(209, 196, 8, 1);
    box-shadow: 0px 0px 10px 5px rgba(209, 196, 8, 1);
}

.filePreviewModalContent {
    overflow-y: initial !important
}


.div-height {
    height: 30px;
}

.div-flex {
    display: flex;
}

.year-dropdown-align {
    justify-content: flex-end;
}

.year-dropdown-width {
    width: 68px;
}

.fontfamily-dropdown-width {
    width: 100px;
}

div#attachmentTable td.react-bs-table-no-data {
    border: 0px;
}

.copyIcon {
    display: none;
    cursor: pointer;
}

.copy:hover .copyIcon {
    display: inline-block;
}

.datePicker {
    padding-right: 70px;
}

.eye-icon {
    float: right;
    margin-top: -25px;
    padding-right: 15px;
    position: relative;
}

.page-thumbnail>span {
    display: block;
    position: relative;
}

.page-thumbnail img {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.page-thumbnail:hover .action-buttons span {
    background-color: rgba(0, 0, 0, 0.5) !important;
    opacity: 1 !important;
    transition: all 0.3s ease 0s !important;
}

.overlay-content .page-number {
    color: white !important;
    font-size: 12px;
    font-weight: 700;
    padding: 0px 5px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 0px;
    background: rgba(6, 0, 250, 0.38);
}

.overlay-content .action-buttons {
    bottom: 40%;
    left: 50%;
    position: absolute;
    margin-left: -43px;
}

.action-buttons span {
    border: 1px solid #afafaf;
    display: block;
    height: 35px;
    line-height: 35px;
    text-align: center;
    width: 35px;
    background-color: #afafaf;
    border-radius: 25px;
    color: white;
    opacity: 0.2;
    cursor: pointer;
}

.action-buttons a:hover {
    background-color: #8c8c8c !important;
    opacity: 1 !important;
    transition: all 0.3s ease 0s !important;
}

.overlay-content .action-buttons ul {
    display: flex;
    list-style: outside none none;
}

.overlay-content .action-buttons ul li {
    margin-right: 10px;
}

.overlay-content h2 {
    color: #5a5a5a;
    font-size: 20px;
    font-weight: 700;
    left: 40px;
    position: absolute;
    top: 20px;
    transition: all 0.3s ease 0s;
}


.page-thumbnail .title-bar {
    background: rgb(215, 208, 255);
    color: black !important;
    font-size: 12px;
    font-weight: 700;
    padding: 10px 0px;
    text-align: center;
    transition: all 0.3s ease 0s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    bottom: 0px;
}

.page-thumbnail:hover .title-bar {
    background: rgba(215, 208, 255, 0.33);
}

.modal-GroupFilePreview {
    width: 60%;
    margin: auto;
    overflow-y: initial !important;
    max-height: 100%;
}

.modal-GroupFilePreviewBody {
    height: 75vh;
    overflow-y: auto;
}

.btn-download-bri {
    margin-right: 2%;
}

#popover-voucher-add {
    text-align: center;
}

#popover-voucher-add .icon-textbox {
    text-align: initial
}

.textMessageview {
    background-color: rgb(241, 241, 241);
    height: 132px;
    overflow-y: auto;
    padding: 5px;
}

.word-Visible {
    overflow-wrap: break-word;
    white-space: initial !important;
}

.progress {
    margin-bottom: 0px !important;
}

.pg-viewer-wrapper video {
    width: 100%;
    height: 100%;
}

.taxcaddy-icon {
    font-size: x-large;
    cursor: pointer;
    text-decoration: none !important;
}



.padT08 {
    padding-top: 8px;
}

#process-return-groups-pane-1 .react-datepicker-wrapper {
    display: initial !important;
}

#process-return-groups-pane-1 .react-datepicker__input-container {
    position: relative;
    display: initial !important;
}

.height-inherit {
    height: inherit;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100vw;
}

#process-return-groups .dropdown-menu>div>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

/*=======Dropdown start===========*/
#combodefault .col-lg-9 {
    min-height: 250px;
}

#default {
    margin: 0 auto;
    width: 250px !important;
    padding-top: 15px;
}

#combodefault .property-panel-content,
#combopanel .property-panel-content {
    overflow: hidden;
}

.attachmentDownload {
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 1px;
    padding-top: 4px;
    border: 0px
}



/*=======Dropdown end===========*/

.text-message {
    margin-top: 10px;
    border: 1px solid rgb(221, 221, 221);
    padding: 5px;
    height: 207px;
    overflow: auto;
}

.uploaded-documents-table-container {
    overflow: auto;
    max-height: 70vh;
    font-size: 12px;
}

.text-bold {
    font-weight: bold !important;
}

.zindexzero input[type='text'] {
    z-index: 0 !important;
}

.addingvoucher-loader {
    position: absolute !important;
    height: 100%;
}

.upload {
    background-color: #eee;
    color: black;
    padding: 5px 4px;
}

.overflowHidden {
    overflow: hidden !important;
}

.overflowVisible {
    overflow: visible !important;
}


.bookmark-item {
    padding: 8px 8px;
}

.bookmark-name {
    display: inline;
    margin-right: 8px;
}

.bookmark-name:hover {
    text-decoration-color: #337ab7 !important;
    text-decoration: underline;
}

.bookmark-row {
    display: inline-flex;
    width: 100%;
}

.bookmark-icon {
    margin-right: 8px;
    line-height: unset;
}


#process-return-groups-pane-1 .react-datepicker__input-container {
    position: relative;
    display: initial !important;
}

.height-inherit {
    height: inherit;
}

#process-return-groups .dropdown-menu>div>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.dropdown-menu-ul {
    right: 100% !important;
    max-width: none !important;
    left: unset !important;
}

.dropdown-submenu-right>a:after {
    float: right !important;
}

.bold-label>div>.magic-checkbox+label,
.boldlable>div>.magic-radio+label {
    font-weight: bold !important;
}

.zero-amount-badge {
    background-color: grey;
    border-radius: 25px;
    width: 26px;
    max-height: 19px;
    color: white !important;
}

.delete-zero-amount-button {
    margin-right: 10px;
    background: orange;
    height: 24px;
    width: 24px;
    border-color: #ffc400;
}

.delete-zero-icon {
    padding-bottom: 6px;
    height: 22px;
    width: 17px;
}

.access-code-note {
    text-align: left;
    padding-top: 19px;
    font-size: smaller;
    color: #e31313;
}

.replace-invoice {
    font-size: 12px;
    border-radius: 0px !important;
    border: 1px solid #ccc;
    color: #eee,
}

.bookmark-right {
    margin-left: auto;
    white-space: nowrap;
}

.bookmark-right i {
    line-height: unset;
}

.bookmark-right i:hover {
    background-color: #d0d0d3;
    border: solid 1px #595959;
    border-radius: 3px 3px;
}

.client-info-payments {}

.bookmark-right .client-info-payments {
    font-family: "'Lucida Console', 'Monaco', 'monospace'";
    margin-right: 8px;
    text-align: right;
    color: red;
}

.client-info-refunds {}

.bookmark-right .client-info-refunds {
    font-family: "'Lucida Console', 'Monaco', 'monospace'";
    margin-right: 8px;
    text-align: right;
    color: green;
}

.textarea {
    overflow: auto;
    resize: vertical;
}

.text-green {
    color: #5CB85C
}

.text-yellow {
    color: #FFD700
}


#div-delivered-returns-search,
#div-archive-returns-search,
#div-inuse-returns-search,
#div-Assignment-Table-search {
    float: left;
}

.react-bs-table-search-form {
    width: 100%;
}

.react-bs-table-container .react-bs-table-search-form .form-control {
    font-size: 12px;
    height: 32px;
    margin-right: 5px;
}

.modal.view-access-code-modal .modal-dialog {
    width: 60%;
}

.modal.unlock-modal .modal-dialog {
    width: 40%;
}

.modal.modal.unlock-modal .modal-body > div:not(:last-child) {
    margin-bottom: 15px;
}

.bookmark-icon-image {
    max-width: 16px !important;
    max-height: 16px !important;
    margin-right: 8px;
}

.overflowTextClientTracking>div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

#ero {
    font-size: 14px !important;
}

#report-problem-text {
    resize: none;
}

.display-checkBox {
    display: inline-block !important;
    margin-right: 10px;
}

.zeroborderRadius>span {
    border-radius: 0px !important;
}

.fa-stack {
    font-size: 0.45em;
}

.activeSelected {
    background: #3399ff !important;
    color: white !important;
}

.group_user ul li {
    list-style-type: none;
}

.group_user ul li:hover {
    cursor: default !important;
    background: #c3c3eb;
}

input.e-input,
.e-input-group input.e-input,
.e-input-group input,
.e-input-group.e-control-wrapper input.e-input,
.e-input-group.e-control-wrapper input,
.e-float-input input,
.e-float-input.e-input-group input,
.e-float-input.e-control-wrapper input,
.e-float-input.e-control-wrapper.e-input-group input,
.e-input-group,
.e-input-group.e-control-wrapper,
.e-float-input,
.e-float-input.e-control-wrapper {
    border-radius: 0px !important
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 0px !important;
}

.padding-left-6 {
    padding-left: 6%;
}

th[data-field="clientApp"] {
    width: 30%;
}

.modal.signed-Details-modal .modal-dialog {
    width: 40%;
}

.modal.delivery-status-modal .modal-dialog {
    width: 50%;
}

.modal.recall-return-modal .modal-dialog {
    width: 40%;
}

.recall-return-table {
    border-collapse: collapse;
    width: -webkit-fill-available;
}

table.recall-return-table th,
table.recall-return-table td {
    border: 1px solid #ccc;
    text-align: left;
    padding: 5px;
    padding-left: 8px;
}

.custom-multiselect-status {
    margin-left: 3px;
    vertical-align: baseline;
    position: relative;
    bottom: 3px;
}

.ssr-default {
    pointer-events: none;
    background-color: rgb(241, 236, 236);
}

.minimum-length {
    padding-top: 20px;
    padding-bottom: 20px;
}

.select-age {
    padding-top: 20px;
    padding-bottom: 10px;
}

.age-text {
    float: left;
    font-size: 12px;
    margin-left: 15px;
}

.select-age-dropdown {
    width: 8%;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
}

.password-req-lable {
    color: red;
    text-decoration: underline;
}

.line-space {
    padding-top: 10px;
    padding-left: 30px;
}

.list-special-char {
    color: green;
    padding-left: 45px;
}

.tooltip {
    position: relative;
    display: inline-block;
    opacity: 1;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -50px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.Select .Select-menu-outer {
    width: 100% !important;
    font-size: 14px;
    color: #212529 !important;
}

.Select-menu-outer {
    color: aqua !important;
}

.voucher-value {
    width: 180px;
}

.k1settings-value {
    width: 204px;
}

.select-control {
    width: 140px;
    float: left;
    padding-right: 15px;
}

.settings-subheading {
    font-size: 16px;
    text-decoration-line: underline;
    margin-left: 20px;
}

.marT09 {
    margin-top: 9px;
}

.select-reminder-control {
    width: 120px !important;
}

.bannerHeader {
    color: #FF0020;
    font-size: 1.5rem !important;
    font-weight: bold;
    padding-left: 50px;
    flex: 6;
}

.marTNeg03 {
    margin-top: -3px !important;
}

.Select.is-disabled>.Select-control {
    background-color: #eeeeee !important;
}

.columnvalue {
    overflow: hidden;
    width: 80%;
    display: inline-block;
}

.icon-color {
    color: #15aabf;
    padding-right: 4px;
}

ul.moveable li {
    list-style-image: none;
    border-radius: 4px;
    padding: 4px;
    color: #666;
    cursor: move;
}

ul.moveable li:hover {
    background-color: #eee;
}

.draggable-input:hover {
    cursor: move;
}

span.action-icons>i:hover,
span.action-icons>i {
    cursor: pointer !important;
    pointer-events: auto;
}

.select-reminder-control {
    width: 120px !important;
}

div.optionlist {
    position: absolute;
    z-index: 200;
    background-color: #fff;
    left: auto;
    text-align: left;
    list-style: none;
    border: 1px solid #aaa;
    right: 21px;
    white-space: nowrap;
}

div.optionlist>div.item {
    line-height: 24px;
    padding: 0 10px;
}

div.optionlist>div.item-highlighted {
    color: #fff;
    background-color: #0078d7;
}

.isDisabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.isDisabled a {
    color: currentColor;
    display: inline-block;
    /* For IE11/ MS Edge bug */
    pointer-events: none;
    text-decoration: none;
}

.popovereditinfo .arrow {
    left: 35% !important;
}

.popovereditinfo {
    left: -100px !important;
    font-size: 14px;
}


/* Below styles are for multiple voucher popover alert. */
#popover-voucher-add {
    padding: 0px;
}

#popover-voucher-add .popover-content {
    padding: 0px;
}

#popover-voucher-add .new-main-content {
    padding: 9px 14px;
}

#popover-voucher-add .popover-content .alert {
    border-radius: 0px;
}

/* Multiple voucher popover alert style end */

.resize-vertical {
    resize: vertical;
}

.resize-horizontal {
    resize: horizontal;
}

.recycle-checkbox {
    margin-top: 4px;
}


.div-chip-list {
    align-items: center;
    border: 2px solid #d4d5d6;
    padding: 4px;
    overflow-y: auto;
    max-height: 100px;
}

.chip-input {
    height: 34px;
    padding: 0 1rem;
    box-sizing: border-box;
    font: inherit;
    border-radius: 0.2rem;
    border: none;
    color: #565656;
    -webkit-appearance: none;
}

.chip {
    border: 1px solid #d4d5d6;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 3px 0;
}

.chip>button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chip>.drag {
    cursor: move;
}

.no-suggestions {
    color: #999;
    padding: 0.5rem;
}

.suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(300px + 1rem);
    position: absolute;
    z-index: 1;
    background-color: white;
}

.suggestions li {
    padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
    background-color: aliceblue;
    cursor: pointer;
}

#sf-txt-area-msg {
    height: 300px;
    margin-top: 2%;
    padding-left: 5px;
}

.sfSigners {
    color: #337ab7 !important;
}

.sfSigners:hover {
    text-decoration: underline !important;
}

.sigflow-doc-access-list {
    text-align: center;
    padding-top: 5%;
}

.sfSigners:hover {
    text-decoration: underline !important;
}

/* The switch - the box around the slider */
.toggleSwitch {
    position: relative;
    display: inline-block;
    width: 60px;
}

/* Hide default HTML checkbox */
.toggleSwitch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.columnFilterSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    width: 40px;
    height: 12px;
    display: block;
    margin: 10px 0px 0px 0px;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3) !important;
    -webkit-transition: 0.5s ease background-color;
    background-color: #d9e3f4;
    border-radius: 20px;
    transition: 0.5s ease background-color
}

.columnFilterSlider:before {
    position: absolute;
    content: "";
    width: 17px;
    height: 17px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 2px 6px 25px #d7d7d7;
    transform: translate(-2px, -2px);
    transition: 0.6s ease transform, 0.6s box-shadow;
}

input[type="checkbox"]:checked+.columnFilterSlider {
    background-color: #92D9F8;
}

input[type="checkbox"]:checked[disabled]+.columnFilterSlider {
    opacity: 0.6;
}

input:focus+.columnFilterSlider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.columnFilterSlider:before {
    box-shadow: 7px 6px 25px #115980 inset;
    transform: translate(25px, -2px);
}

input:checked[disabled]+.columnFilterSlider:before {
    opacity: 0.6;
}

input:checked+.columnFilterSlider:after {
    width: 24px;
    border-radius: 50%;
    transform: translate(64px, 0px);
}



/* Rounded sliders */
.columnFilterSlider.round {
    border-radius: 34px;
}

.columnFilterSlider.round:before {
    border-radius: 50%;
}

.noOutline {
    outline: none;
    border: 0px solid !important;
    border-top-style: hidden;
    background-color: transparent !important;
    box-shadow: none;
    font-weight: bold;
    font-size: 14px;
}

.noOutline:hover {
    background-color: #eee;
    outline: none;
    opacity: 1;
}

.cancelDocumentTextArea {
    border: solid 2px #21a9e1;
    min-height: 100px;
    width: 100%;
    margin: 3px 0px 3px 0px;
    font-size: inherit;
}

.icon-color {
    color: #17a2b8;
}

.viewWidth70 {
    width: 70vw !important;
}

.viewWidth85 {
    width: 85vw !important;
}

.viewWidth90 {
    width: 90vw !important;
}

.separatorLine {
    border: 1.3px solid black;
    height: 35px;
}

.popup-btn-white {
    background: #FAFAFA;
    border: 0px #e0e0e0 solid;
    color: #303641;
    margin-right: 2px;
}

.popup-btn-white:hover {
    background: #ebebeb;
}

.popup-btn-white i {
    text-shadow: 0px 1px 0px #ebebeb;
}


.textfield-control {
    margin-left: 14px;
    margin-right: -14px;
}



#ddlUploadSFDocument .Select-arrow {
    margin-top: 10px;
}

div.formNameList {
    position: absolute;
    z-index: 200;
    background-color: #fff;
    left: auto;
    text-align: left;
    list-style: none;
    border: 1px solid #aaa;
    white-space: nowrap;
}

div.formNameList>div.item {
    line-height: 24px;
    padding: 0 10px;
}

div.formNameList>div.item-highlighted {
    color: #fff;
    background-color: #0078d7;
}

.sfSigner>.Select--single>.Select-control .Select-value {
    max-width: 97%;
}

.sf-compulsory-field {
    color: #c50505;
    font-size: 9px;
    position: absolute;
    padding-left: 7px;
    top: 8px;
}

.doc-access-auth-title {
    padding-top: 20px;
    padding-left: 20px;
}

/* Notification Settings  */
.notificationPopover {
    max-width: none;
    /* Max Width of the popover (depending on the container!) */
    font-size: 12px;
    overflow: hidden;
    width: 500px;
    height: 550px;
    resize: both;
    min-width: 300px;
    min-height: 250px;
}

.notificationPopover .popover-content {
    height: 100%;
}

#inbox-tab {
    height: 100%;
}

#inbox-tab .tab-content {
    height: 90%;
}

#inbox-tab .tab-content .tab-pane {
    height: 94%;
}

.settingsPanel {
    max-height: 47vh;
    overflow-y: auto;
}

.settings {
    color: grey;
    padding: 4px 2px;
    text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
    font-size: medium;
}

.settings:hover {
    color: grey;
}

.pad-settings {
    padding: 4px 8px;
}

.btn-settings {
    width: 40px;
}

/* Notification Settings  */

.inbox-count {
    background-color: #ff6666;
    font-size: 8px;
    padding: 3px;
}

.support-inbox-tabs {
    padding: 15px 0px 0px 0px;
    height: 96%
}

.support-inbox-active-tab {
    background-color: #f5f5f6;
}

ul.support-inbox-tab-title>li {
    display: inline;
    padding: 15px;
    cursor: pointer;
}

ul.support-inbox-tab-title>li:hover {
    background: #f5f5f6;
}

.support-inbox-knownissue {
    color: #ff6666;
}

.support-inbox-releaseupdate {
    color: forestgreen;
}

.support-inbox-processingitem {
    color: dodgerblue;
}

.support-inbox-others {
    color: #ff9900;
    font-size: 8px;
    vertical-align: top;
}

.support-inbox-count {
    vertical-align: sub;
    margin-left: -3px;
    font-weight: bold;
}

.support-inbox {
    position: relative;
    height: 96%;
}

.support-inbox-heading {
    text-align: center;
    color: white;
    padding: 5px;
    background-color: darkgrey;
    font-size: 14px;
}

.support-inbox-content {
    overflow-y: scroll;
    border: antiquewhite;
    border-width: thin;
    border-style: solid;
    height: 92%;
}

.support-message {
    width: 100%;
    padding: 0px 10px;
    display: inline-table;
    cursor: pointer;
    margin-bottom: 5px;
    border: 1px solid lightgoldenrodyellow;
}

.support-message-break {
    margin-top: 0px;
    margin-bottom: 0px;
}

.support-message-header {
    padding-top: 5px;
    display: flex;
}

.support-message-title {
    overflow: hidden;
    width: 75%;
    cursor: inherit;
}

.support-message-date {
    font-size: 10px;
    color: darkgrey;
}

.support-message-body {
    padding-bottom: 5px;
    text-align: justify;
}

.support-message-seemore {
    color: dodgerblue;
    margin-bottom: 0px;
}

.firm-inbox-content {
    overflow-y: scroll;
    max-height: 460px;
    border-width: thin;
    border-bottom-style: groove;
}

.firm-message {
    padding: 5px 10px 5px 10px;
    cursor: pointer;
}

.firm-message-body {
    display: inline-flex;
}

.firm-message-text {
    width: 95%;
    padding-right: 10px;
}

.firm-message-clear {
    width: 5%
}

.firm-message-date {
    font-size: 10px;
    color: steelblue;
}

.firm-message-clear-icon {
    padding: 5px
}

.firm-message-unsubscribe-icon {
    color: orangered;
    padding-top: 5px
}

.text-dodgerblue {
    color: dodgerblue;
    cursor: pointer;
}

.unreadMessage {
    background-color: #ffffcc;
}

.support-message-clear {
    font-size: initial;
    font-weight: 700;
    margin-top: -9px;
    color: #c50505;
}

.w30 {
    width: 30px;
}

.checkbox-helper {
    display: inline-block;
    position: absolute;
    right: 0px;
    z-index: 10000000;
}

.disableAnchor {
    pointer-events: none;
}

.enableAnchor {
    pointer-events: all;
}

.canvasProperty {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
    border: 3px solid #037996;
}

.divCanvasProperty {
    border: 2px outset black;
    background: white;
    text-align: center;
    height: 100%;
    width: 100%;
    position: relative;
    margin: 0px;
}

.watermarkDraggerProperty {
    display: flex !important;
    border: solid 2px #037996;
    background: white;
    opacity: 0.5;
}

.watermarkIconwidth {
    width: 1.5em !important;
}

.list-title {
    font-weight: 600 !important;
    font-size: 15px !important;
    text-transform: capitalize;
}

.list-sub-title {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: rgba(0, 0, 0, .55) !important;
    font-size: 14px !important;
    text-transform: capitalize;
}

.senderdelegation-glyphicon {
    font-size: initial;
    top: 4px;
    padding-left: 5px;
}


/*---TaxDocument Upload FAB button style starts here---*/

.cch-btn,
.ut-btn,
.gs-btn,
.lt-btn {
    cursor: pointer;
    position: absolute;
    right: 1.5em;
    bottom: -36px;
    padding: 10px 0px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    z-index: 1;
    color: #fff;
    align-items: center;
    text-align: center;
}


.cch-btn {
    background-color: #7abf48;
}

.ut-btn {
    background-color: #454545;
}

.gs-btn {
    background-color: #f49011;
}

.lt-btn {
    background-color: #c09550;
}

/*---TaxDocument Upload FAB button style ends here---*/


.settings-header {
    color: #1a8fbf;
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 16px;
}

.upload-header {
    text-align: center;
    margin: auto;
    font-size: 14px;
}

.upload-container {
    border: 1px solid gray;
    overflow: hidden;
}

.upload-footer {
    text-align: center;
    border-top: 1px solid gray;
    font-size: 14px;
    margin: auto;
    width: 100%;
}

.batch-upload-options {
    display: inline-flex;
}

.batch-upload-options-item {
    margin: auto;
    font-size: 14px;
}

div.question-list:empty:before {
    content: attr(data-placeholder);
    color: gray
}

.batch-upload-options-checkBox {
    padding-right: 15px;
    padding-left: 15px;
}

.additional-question-template {
    height: 100%;
}

.additional-question-template .h100 {
    height: 100%;
}

.border-none {
    border: none;
}

.drag-question {
    cursor: default;
}

.beforeQuestionNo {
    font-weight: 700;
    position: relative;
    display: inline-block;
}

.question-limit {
    position: absolute;
    font-size: 10px;
    top: 0;
    right: 5px;
}

.question-no {
    display: flex;
    align-items: baseline;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.pull-right .btn {
    padding: 9px 12px 9px 12px !important;
}

#move-to-dropdown {
    padding: 6px 12px 6px 12px !important;
    margin-right: 3px;
    background-color: #f0ad4e;
    color: white;
    font-size: 12px;
}

.dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.react-bootstrap-table-page-btns-ul {
    font-size: 14px;
}

.bootbox-body {
    font-size: 14px;
}

.dropdown,
.dropup {
    position: relative;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
}

.react-bs-container-body table tbody tr td button i {
    padding: 4px;
}

.text-primary {
    color: #337ab7;
}

.text-subHeading {
    color: #007bff !important;
    font-weight: 700;
}

.background-danger {
    background-color: #ff000059;
}

.minW50 {
    min-width: 50px !important;
}

.download-details {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
}

.report-problem-field {
    margin-bottom: 7px;
}

.font700 {
    font-weight: bold;
    font-size: 12px
}

.reminder-text {
    margin-left: 16px;
    margin-bottom: 10px;
    font-size: 14px;
}

.dropdown-component {
    font-size: 14px;
    width: 250px;
}

.btn-white:hover {
    background: #ebebeb !important;
    border-color: #adadad !important;
}

.Refresh-button {
    color: #333 !important;
    background-color: #fafafa !important;
}

.Refresh-button :focus,
.btn-primary.focus {
    color: #333;
    box-shadow: #80808040 0px 0px 3px;
    border-color: none !important;
}

.Refresh-button:hover,
.btn-primary.hover {
    color: #333;
    box-shadow: #80808040 0px 0px 3px;
    background-color: #ebebeb !important;
    border-color: #adadad !important;
}

.Refresh-button :not(:disabled):not(.disabled):active {
    color: #333;
}

.Reminder-Option .modal-content {
    width: 450px;
}

.reminder-multi-tab .modal-content {
    width: 80%;
    max-width: 592px;
    margin: 0 auto;
}

.ReminderOption lable {
    font-size: 15px;
}

.reminder-multi-tab .modal-header .close,
.Reminder-Option .modal-header .close {
    font-size: 21px;
    padding-top: 10px;
    font-family: Tahoma;
}

.label-control {
    background-color: white !important;
    font-weight: 500 !important;
}

.awesome-pdf-viewer-radio-button-label {
    border: 1px solid black !important;
    height: 25px !important;
    width: 30px !important;
}

.question-delete {
    color: red;
    cursor: pointer;
    right: -10px;
    position: absolute !important;
    font-size: 15px !important;
}

.right-panel {
    width: 600px !important;
}

.toolbar .middle-section,
.toolbar .left-section,
.toolbar .right-section {
    padding: 6px 5px !important;
}

.icon-bar a {
    font-size: 10px;
    padding-top: 10px !important;
}

.toolbar-item-label {
    font-size: 10px;
}

.document-element-control {
    border-radius: 0px;
    cursor: pointer;
    display: flex;
    position: absolute;
    z-index: 1;
}

.add-question-label {
    padding-top: 8px;
}

.add-question .row {
    margin-bottom: 10px;
}

.add-question label {
    font-size: 14px;
}

.add-question .modal-header {
    padding: 15px;
}

.add-question .modal-body {
    padding: 15px;
}

.add-question .modal-content {
    width: 750px;
}

.dropdown-sub-question {
    font-size: 14px;
    width: 150px;
}

.defaultQuestion {
    font-size: 13.33px;
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
}

.section {
    font-size: 18px;
    margin-top: 30px;
    color: #88c656;
}

.customQuestion {
    font-size: 13.33px;
    margin-left: 40px;
    margin-top: 30px;
    display: flex;
}

.subQuestion {
    font-size: 13.33px;
    margin-left: 70px;
    margin-top: 20px;
    display: flex;
}

.defaultSubQuestion {
    font-size: 13.33px;
    margin-left: 30px;
    margin-top: 20px;
    display: flex;
}

.customQuestion i {
    font-size: initial;
}

.marT30 {
    margin-top: 30px;
}

.add-section .modal-content {
    width: 450px;
}

.edit-icon {
    color: #0973ba;
    font-size: 13px;
    cursor: pointer;
}

.delete-icon {
    color: red;
    font-size: 13px;
    cursor: pointer;
}

.padT20 {
    padding-top: 20px;
}

.customDefaultQuestion {
    font-size: 14px;
    margin-top: 40px;
    display: flex;
    margin-left: 5px;
}

.marT40 {
    margin-top: 40px;
}

.textbox-control {
    border: 1px solid #343a40 !important;
    background-color: white !important;
}

#rightSidebarToggle {
    float: left;
    color: teal !important;
    text-align: left;
    width: 100%;
    border: dashed 1px grey;
}

.rightSidebar {
    width: 50px !important;
    background-color: rgb(255, 255, 255);
    min-height: calc(100vh - 150px);
    user-select: none;
    border: 1px grey;
    border-style: outset;
    border-top: none;
    border-bottom: none;
}


.rightSidebar.navbar-nav #rightSidebarToggle::after {
    width: 1rem;
    text-align: center;
    float: left;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: '\f101';
    font-family: 'Font Awesome 5 Free';
}

.rightSidebar.navbar-nav.toggled #rightSidebarToggle::after {
    width: 1rem;
    text-align: center;
    float: left;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: '\f100';
    font-family: 'Font Awesome 5 Free';
}

@media (min-width: 768px) {
    .rightSidebar {
        width: 175px !important;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-right: none;
    }

    .rightSidebar .nav-item .nav-link {
        display: block;
        width: 100%;
        text-align: left;
        padding: 1rem;
        width: 175px;
    }

    .rightSidebar .nav-item .nav-link span {
        font-size: 1.5rem;
        display: inline;
    }

    .rightSidebar .nav-item .layout-dropdown-menu {
        box-shadow: none;
        position: static !important;
        margin-left: 30px;
        top: 0;
        background-color: transparent;
        border: none;
    }

    .rightSidebar .nav-item.layout-dropdown .layout-dropdown-toggle::after {
        display: block;
    }

    .rightSidebar.toggled {
        overflow: visible;
        width: 50px !important;
    }

    .rightSidebar.toggled .nav-item:last-child {
        margin-bottom: 1rem;
    }

    .rightSidebar.toggled .nav-item .nav-link {
        text-align: center;
        padding: 1.5rem 1rem;
        width: 50px;
    }

    .rightSidebar.toggled .nav-item .nav-link span {
        font-size: 0.65rem !important;
        display: none;
    }

    .rightSidebar.toggled .nav-item .nav-link i {
        font-size: 20px !important;
    }

    .rightSidebar.toggled .nav-item .layout-dropdown-menu {
        position: absolute !important;
        -webkit-transform: none !important;
        transform: none !important;
        left: calc(45px + 0.5rem) !important;
        margin: 0;
        background-color: white;
        border: 1px solid #d5d5d5;
        box-shadow: 0 0 15px #dcdcdc;
    }

    .rightSidebar.toggled .nav-item .layout-dropdown-menu.dropup {
        bottom: 0;
        top: auto !important;
    }

    .rightSidebar.toggled .nav-item.layout-dropdown .layout-dropdown-toggle::after {
        display: none;
    }
}

ul.warningPoints {
    list-style-type: disc;
    padding-left: 40px;
}

ul.warningPoints span {
    font-weight: 500
}

.warningModalIcon {
    color: #ffc107 !important;
}

.CustomQuestion-Warning .modal-content {
    width: 600px !important;
}

.RightPanelText {
    display: flex !important;
    margin: -28px 10px 10px 40px;
}

.email-text-area {
    height: 100%;
    max-height: 119px;
    overflow: auto;
    width: 39.5vw;
}

.text-message {
    height: fit-content;
    width: fit-content;
    max-height: 100vh;
    overflow: none;
    -ms-overflow-style: auto !important;
    /* IE and Edge */
    scrollbar-width: none !important;
    /* Firefox */
}

.text-message::-webkit-scrollbar {
    display: none !important;
}

@media only screen and (max-width: 1600px) {
    .email-text-area {
        width: 39.125vw;
    }
}

@media only screen and (max-width: 1400px) {
    .email-text-area {
        width: 38.75vw;
    }
}

.uploadTabHeader {
    font-size: 14px;
    font-weight: bold !important;
}

.upload-doc-modal-body>.tab-content {
    overflow: hidden !important;
}

.edit-esign-modal .modal-content {
    width: 570px;
}

.general-settings-row {
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    margin-left: 16px;
}

.general-settings-checkbox {
    width: 15px;
    height: 15px;
}

/* My Downloads*/
.my-download-title {
    color: grey;
    margin-right: 5px;
}

.my-download-file-icon {
    color: #1a8fbf;
    margin-left: 20px;
    margin-top: 10px;
}

.my-download-row-toggle {
    color: grey;
    margin-right: 5px;
    margin-top: 16px;
    margin-left: 24px;
    cursor: pointer;
}

.my-download-delete {
    margin-top: 16px;
    font-size: 20px;
    color: #dc3545 !important;
    cursor: pointer;
}

.my-download-expand {
    color: grey;
    text-align: left;
    margin-left: 37px;
    font-size: 12px;
}

.my-download-expand .file-name {
    /* max-width: 85%; */
    /* display: block; */
}

.my-download-filter {
    border: hidden;
    color: grey;
}

.my-download-filter-row {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 244px
}

.my-download-status-icon {
    color: #23527c;
    font-size: 20px;
    margin-top: 16px;
}

.my-download-popover {
    color: grey;
    font-size: 12px;
}

.senderdelegation-glyphicon {
    font-size: initial;
    top: 4px;
    padding-left: 5px;
}

.client-instructions-email-message-icon-row-wrapper {
    min-width: fit-content !important;
    margin-left: 32px;
}

.session-timeout-modal-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.od-modal-preview-icon {
    color: #0570b8;
}

.od-modal-preview-icon-container {
    position: relative;
    top: 1.5px;
    margin-left: 10px;
}

.od-modal-preview-icon-container:hover {
    cursor: pointer;
}

.filePreviewModalContent {
    overflow-y: initial !important
}

.nopadding {
    padding: 0 !important;
}

/* Photo document viewer support start */

.photo-viewer-container {
    margin: 0 auto;
    width: 90% !important;
    height: 90% !important;
}

.photo {
    width: 100% !important;
    height: 100% !important;
}

/* Photo document viewer support end */

.react-grid-Canvas {
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: auto scroll;
    width: 762px;
    height: 580px !important;
}

.change-status-error {
    font-size: 11px;
}

.help-centre-menu-item-container {
    display: flex;
    align-items: flex-end;
    position: absolute;
    width: 225px;
    bottom: 17px;
    font-size: 16px;
}

.side-collapse-menu-item-container {
    width: 50px;
    font-size: 20px;
    bottom: 22px;
}

.help-centre-menu-item {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: #555464;
    width: 100%;
    height: 42.5px;
    padding-left: 8px;
    margin-bottom: 1rem;
}

.side-collapse-menu-item {
    justify-content: center;
    padding-left: 0px;
}

.help-centre-menu-item:hover {
    text-decoration: none;
    color: #000;
    background-color: rgba(0, 0, 0, 0.05);
}

.help-centre-menu-item:focus {
    outline: none;
    text-decoration: none;
    color: #555464;
}

.header-support-item-container {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
}

.general-settings-header {
    display: flex;
    align-items: center;
    margin-top: 14px;
    margin-bottom: 14px;
}

.general-apply-button {
    position: absolute;
    right: 34px;
}

.automatic-remainder-table {
    margin-top: 24px;
    margin-left: 16px;
    font-size: 14px !important;
}

.automatic-remainder-table td:first-child {
    padding-right: 12px;
}

.automatic-remainder-table td:last-child {
    padding-left: 12px;
}

.please-note-msg {
    color: red;
    margin-top: 16px;
    padding-bottom: 2px;
}

.reminders-option-banner {
    background: #D1ECF1;
    margin: -8px -14px 10px -14px;
    height: 32px;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 6px
}

/*--Sort Icon allignment Styling in Grid for Delivered and Archive Return view  ---*/
.table-text-sort {
    display: inline-block;
    width: calc(100% - 21px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.sr-popup-body {
    position: relative;
    padding: 15px;
    font-size: 12px;
}

.sign-orgs-reminder-panel {
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.sign-orgs-reminder-panel-row {
    align-items: center;
    gap: 12px;
}


.generate_link_enabled {
    opacity: 1;
    pointer-events: auto;
}

.generate_link_disabled {
    opacity: 0.5;
    pointer-events: none;
}

.main-container {
    width: 100%;
    padding: 5px 20px 10px 20px;
    overflow-y: auto;
    overflow-x: hidden;
}

.main-container div .row-override {
    margin-right: 0px;
    margin-left: 0px;
    height: 100%;
}

/*Below code added for RBAC. It will remove once available through NPM package.*/
[data-resource-id].disable {
    cursor: not-allowed !important;
    pointer-events: all;
    opacity: 0.65;
}

.side-menu-navbar .active {
    background-color: #13294b;
}

.sub-menu-dropdown {
    padding: 4px 10px !important;
}

.sub-menu-dropdown-item .nav-link {
    color: #ffffff !important;
}

#ssuite-layout-wrapper .side-menu-container .sidebar .group-menu-container {
    padding: 0px 0px !important;
}

#ssuite-layout-wrapper .side-menu-container .sidebar .side-menu-navbar .top-menu-container {
    padding-top: 10px;
}

#ssuite-layout-wrapper .side-menu-container .sidebar .group-menu-container .accordion-item {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: rgba(255, 255, 255, 0.25) !important;
    padding-top: 5px;
    margin-top: 0px;
}

#ssuite-layout-wrapper .side-menu-container .sidebar .group-menu-container:not(:first-child) {
    border: none !important;
    padding-top: 0px;
}

#ssuite-layout-wrapper>header .profile-dropdown-wrapper .dropdown-menu .dropdown-header {
    font-weight: 600 !important;
}

#ssuite-layout-wrapper>header .profile-dropdown-wrapper .dropdown-menu .dropdown-item:not(:last-child) {
    font-weight: 400 !important;
}

#ssuite-layout-wrapper>header .profile-dropdown-wrapper .dropdown-menu .dropdown-item:last-child {
    font-weight: 600 !important;
}

.menu-accordion-header-div span {
    padding-bottom: 3px;
}

.bottom-menu-container {
    padding: 10px 0px;
}

#ssuite-layout-wrapper .side-menu-container .sidebar .group-menu-container .accordion-disabled-item .accordion-button {
    cursor: not-allowed;
}

#ssuite-layout-wrapper .side-menu-container .sidebar .group-menu-container .accordion-disabled-item .accordion-button.collapsed::after {
    opacity: 0.5;
}

#ssuite-layout-wrapper>header .right-child .widget-wrapper {
    margin-right: 0px !important;
}

.flex-column-center-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-screen-container {
    text-align: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10%;
    background-color: #F0F1F1;
}

.dropdown .btn {
    padding: 1px 10px !important;
    padding-top: 0px !important;
}

.select-filter,
.text-filter,
.calendarClass .form-control {
    height: 36px !important;
}

.dropdown .dropdown-toggle::after {
    display: none;
}

.modal-body div .row {
    margin-right: 0px;
    margin-left: 0px;
}

.mulishFont {
    font-family: 'Mulish', sans-serif !important;
}

.wordWrap-breakWord {
    word-wrap: break-word;
}

.custom-questions-tab-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.custom-questions-tab-header-container {
    width: 100%;
    height: 46px;
    padding: 0px 30px 10px 0px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: #212529;
    justify-content: flex-end;
    align-items: center;
    z-index: 1000;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
}

.custom-questions-tab-header-container .dropdown-title {
    font-size: 14px;
    color: #212529;
    padding-top: 8px;
}

.custom-questions-tab-templates-dropdown {
    margin-left: 10px;
    height: 31px;
    width: 185px;
}

.custom-questions-tab-templates-dropdown .Select-menu-outer {
    margin-top: 1px;
    max-height: 180px !important;
}

.custom-questions-tab-templates-dropdown .Select-menu {
    max-height: 178px !important;
}

.custom-questions-tab-templates-dropdown .Select-value {
    padding-right: 22px !important;
}

.custom-questions-tab-templates-dropdown .Select-option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-questions-tab-template-container {
    background-color: #F0F1F1;
    padding: 30px 3%;
    height: calc(100vh - 235px);
    width: 100%;
    overflow-y: auto;
}

.custom-questions-tab-placeholder-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.custom-questions-tab-placeholder-container > svg{
    margin-bottom: 17px;
}

.custom-questions-tab-placeholder-container label {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #000;
}

.custom-questions-tab-template-container .questionnaire-header-container {
    position: relative;
    padding: 1.5% 3.5%;
    border-top: 5px solid #0973ba;
    background: white;
}

.custom-questions-tab-template-container .title {
    color: rgba(9, 115, 186, 1);
    font-weight: 500;
    width: 100%;
    height: 100%;
    min-height: 40px;
    padding: 0px;
    border: none;
    border-bottom: none !important;
    resize: none;
    word-wrap: break-word;
}

.custom-questions-tab-template-container .description {
    font-size: 16px;
    font-style: italic;
    width: 100%;
    height: 100% !important;
    min-height: 40px;
    padding: 5px 0px;
    vertical-align: bottom;
    border: none;
    border-bottom: none !important;
    color: #212529;
    resize: none;
    outline: none;
    word-wrap: break-word;
}

@media (max-width: 1920px) {
    .custom-questions-tab-template-container .title {
        font-size: 30px;
    }
}

@media (min-width: 1920px) {
    .custom-questions-tab-template-container .title {
        font-size: 40px;
    }
}

.draggableItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    margin: auto;
    color: #000;
    border-radius: 0px;
    cursor: grab;
    border-bottom: 1px solid #A6A9AC;
}

.draggableItem:last-child {
    border-bottom: none;
}

.no-horizontal-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.no-horizontal-padding>div {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.section-details {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.section-details-sectionName {
    display: inline-block;
    width: 100%;
    color: #212529;
    font-weight: 700;
    font-size: 16px !important;
}

.section-details-sectionCount {
    color: #6B7075;
    font-weight: 500;
    font-size: 14px;
}

#product-access-denied-btn {
    border-radius: 4px !important;
    background-color: #0973BA;
    cursor: pointer;
    border: 1px;
    width: 22rem;
    height: 3.5rem;
    padding: 0.5rem !important;
    font-size: 1.6rem;
}

.product-access-denied {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    align-self: center;
}

.product-access-denied-text {
    color: #212529;
    height: 10rem;
    width: 100rem;
    font-size: 1.8rem;
}

.product-access-denied-text>div {
    font-weight: 400;
}

.product-access-denied-text>h5 {
    font-weight: 700;
}

.access-denied-wrapper {
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
}

.access-denied {
    align-self: center;
}

.access-denied-text>div {
    font-weight: 400;
}

.access-denied-text>h5 {
    font-weight: 700;
}

.logout-modal-header{
    padding: 1.6rem !important;
}
.common-modal{
    width: 50rem !important
}
.common-modal-header{
    padding: 1.6rem !important;
}
.upload-document-row
{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center ;
    gap:1%;
}
.upload-document-text
{
    width: auto;
    white-space: nowrap;
}
.upload-document-item
{
    width: 25%;
}
.pad1
{
    padding: 1%;
}
.width15
{
    width: 15%;
}
