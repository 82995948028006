.template-modal-container label,
.form-control {
    font-size: 14px;
}

.template-modal-container .form-control {
    font-size: 14px;
    background-image: none !important;
}

.template-modal-container .form-control:valid {
    border-color: #ced4da !important;
}

.template-modal-container label {
    font-weight: bold;
}

.template-modal-container textarea {
    max-height: 15vh;
}

.form-builder-container>header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
}

.form-builder-container .header-top-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.form-builder-container .closeIcon {
    width: 3rem;
    opacity: 0.85;
    cursor: pointer;
    padding: 2px 0px 8px 0px;
}

.form-builder-container .closeIcon:hover {
    opacity: 1;
}

.form-builder-container .header-bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}


.form-builder-container .btn-with-icon>svg {
    margin-right: 8px;
}

.form-builder-container .btn-primary:hover>svg path {
    fill: black !important;
}

.form-builder-container>main>footer>button {
    margin-top: 24px;
}

.form-builder-container .header-bottom-container>span {
    font-size: 20px;
    font-weight: bold;
    max-width: 85%;
    padding-right: 5%;
    display: block;
}

.form-builder-container>header button {
    width: 120px;
    padding: 6px 0px !important;
}

.form-builder-container>header div {
    display: flex;
}

.form-builder-container>header div .btn-outline-secondary {
    margin-right: 10px;
}

.form-builder-container>main {
    background-color: rgba(240, 241, 241, 0.6);
    padding: 25px 23px;
    border-top: 0.25px solid rgba(0, 0, 0, 0.15);
    margin-inline: -20px;
    height: calc(100vh - 188px);
    margin-bottom: -10px;
}

.form-builder-container>main>div {
    box-shadow: 0px 4px 20px rgba(158, 158, 158, 0.3);
    border-radius: 2px;
}

.questionnaire-header-container {
    margin-bottom: 15px;
    position: relative;
    padding: 25px 25px 30px 25px;
    border-top: 5px solid #0973ba;
    background: white;
}

.questionnaire-header-container-with-more-padding {
    position: relative;
    padding: 1.5% 3.5%;
    border-top: 5px solid #0973ba;
    background: white;
}

.questionnaire-header-container .inner-container {
    width: 100%;
}
.questionnaire-header-container .inner-container .description-container{
    margin-top: 15px;
}

.questionnaire-header-container .field-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.questionnaire-header-container .title {
    color: rgba(9, 115, 186, 1);
    font-weight: 500;
    width: 90%;
    min-height: 50px;
    height: 100%;
    padding: 0px 6px;
    border: none;
    border-bottom: 1px solid #C4C6C8 !important;
    resize: none;
    word-wrap: break-word;
}

@media (max-width: 1920px) {
    .questionnaire-header-container .title {
        font-size: 28px;
    }
}

@media (min-width: 1920px) {
    .questionnaire-header-container .title {
        font-size: 40px;
    }
}

.questionnaire-header-container .description {
    font-size: 18px;
    font-style: italic;
    width: 90%;
    min-height: 40px;
    height: 100%;
    margin-top: 1px;
    padding: 2px 6px;
    vertical-align: bottom;
    border: none;
    border-bottom: 1px solid #C4C6C8 !important;
    color: #212529;
    resize: none;
    outline: none;
    word-wrap: break-word;
}

.questionnaire-header-container .feedback {
    width: 100%;
}

.questionnaire-header-container .form-control:valid {
    border-color: transparent;
    background-image: none !important;
}

.questionnaire-header-container .form-control:invalid {
    border-color: transparent;
    background-image: none !important;
}

.questionnaire-header-container input:focus {
    outline: none;
}

.questionnaire-header-container .invalid-feedback {
    font-size: 14px;
}

.questionnaire-header-container p {
    font-size: 16px;
    position: absolute;
    bottom: 15px;
    right: 2%;
}

.questionnaire-header-container p i {
    color: rgba(204, 74, 67, 1);
    font-size: 27px;
}

.cq-error-red {
    color: rgba(204, 74, 67, 1) !important;
}

.questionnaire-header-container input::-webkit-input-placeholder {
    color: #c4c6c8;
}

.questionnaire-header-container .error-text {
    font-size: 14px;
    position: absolute;
    margin-bottom: 0;
    color: rgba(204, 74, 67, 1);
    left: 0;
    z-index: 100;
    right: 0;
    bottom: -18px;
}

.form-footer {
    padding-bottom: 25px;
}